<template>
  <div>
    <h1 class="text-center font-black text-xl">Modifier vos informations</h1>

    <div class="my-10">
      <label for="iban" class="block text-sm font-medium text-gray-700">
        {{ $t("Iban") }}
      </label>
      <FormField name="iban" v-model="formData.iban" id="iban" />
    </div>

    <div class="my-10">
      <label for="billing_address" class="block text-sm font-medium text-gray-700">
        {{ $t("Billing address") }}
      </label>
      <FormField name="billing_address" v-model="formData.billingAddress" id="billing_address" />
    </div>

    <div class="my-10" v-if="store.source === 'offline'">
      <label for="shipping_address" class="block text-sm font-medium text-gray-700">Adresse postale</label>
      <LocationField name="shipping_address" v-model="formData.shippingAddress" id="shipping_address" />
    </div>

    <div class="flex justify-center">
      <button
        type="button"
        class="inline-flex items-center rounded-2xl border border-transparent text-button-font-primary bg-primary uppercase px-6 py-3 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
        @click="update"
      >
        Mettre à jour
      </button>
    </div>
  </div>
</template>

<script>
import { FormField } from "@estaly/ui";
import StoreService from "@/api/services/store";
import { LocationField } from "@estaly/ui";
import { mapActions } from "vuex";

export default {
  components: {
    FormField,
    LocationField,
  },

  props: ["openModal", "store"],

  data() {
    return {
      formData: {
        iban: this.store.iban,
        billingAddress: this.store.billingAddress,
        shippingAddress: this.store.shippingAddress,
      },
    };
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    async update() {
      const response = await StoreService.updateStore(this.store.id, this.formData);
      if (!response?.success) return;

      await this.notify({
        category: "simple",
        type: "success",
        title: "Mise à jour effectuée",
      });
      this.$emit("updated");
    },
  },
};
</script>
