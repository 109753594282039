<template>
  <div>
    <h1 class="text-4xl font-heading-1 pl-2 tracking-tight text-gray-900 my-4">{{ $t("My statements and bills") }}</h1>
    <div class="my-7 flex flex-col sm:flex-row justify-between gap-y-4 sm:gap-y-0">
      <div class="flex flex-col sm:flex-row w-full gap-y-4 sm:gap-10 items-center">
        <MonthlyDateFilterSelector
          class="w-1/4"
          placeholder="Sélectionner une période"
          @newDateSelected="updateDateFilters"
        />
        <SearchBarFilter
          v-for="(filterCategory, filtersName, filterIndex) in availableFiltersWithOptions"
          :key="filterIndex"
          :filterName="filtersName"
          :filterIdx="filterIndex"
          :current-page="currentPage"
          :available-filters="filters"
          :selected-filters="currentFilteringData"
          @updateFilterSearch="updateCurrentFilteringData"
        />
      </div>
      <SecondaryButton @click="exportSummary">Exporter</SecondaryButton>
    </div>
    <IbanMissing v-if="ibanMissing" />
    <div>
      <InvoicesContent :invoices="invoices" @redirectToInvoiceDetailsPage="redirectToInvoiceDetailsPage" />
      <Pagination
        :currentPage="currentPage"
        :totalPages="totalPages"
        :totalCount="totalCount"
        @changePage="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import AdminService from "../../../api/services/admin";
import InvoicesContent from "@/components/invoice/InvoicesContent.vue";
import { SearchBarFilter } from "@estaly/ui";
import MonthlyDateFilterSelector from "@/components/menu/MonthlyDateFilterSelector.vue";
import IbanMissing from "@/components/IbanMissing.vue";
import { setQueryParams, getQueryParams, arrayFilterIfPresent } from "@/utils/query_params";
import { Pagination, SecondaryButton } from "@estaly/ui";
import { mapActions } from "vuex";

export default {
  components: {
    Pagination,
    InvoicesContent,
    SearchBarFilter,
    MonthlyDateFilterSelector,
    IbanMissing,
    SecondaryButton,
  },

  computed: {
    availableFiltersWithOptions() {
      return Object.fromEntries(
        Object.entries(this.filters).filter(([filterKey, filterOptions]) => filterKey && filterOptions?.length > 1),
      );
    },
  },

  data() {
    return {
      invoices: [],
      filters: {
        organizations: [],
        stores: [],
        payment_statuses: [],
        transaction_types: [],
      },
      totalPages: null,
      totalCount: null,
      currentPage: 1,
      currentFilteringData: {
        organizations: [],
        stores: [],
        payment_statuses: [],
        transaction_types: [],
        payment_start_date: null,
        payment_end_date: null,
      },
      activeTab: 1,
      billAmount: null,
      statementAmount: null,
    };
  },

  mounted() {
    this.initQueryParams();
    this.retrieveInvoices();
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    handleChangePage(page) {
      this.currentPage = page;
      this.retrieveInvoices();
    },

    updateCurrentFilteringData(filterName, filterOptions) {
      this.currentFilteringData[filterName] = filterOptions;
      this.handleChangePage(1);
    },

    updateDateFilters(startDate, endDate) {
      this.currentFilteringData["payment_start_date"] = startDate;
      this.currentFilteringData["payment_end_date"] = endDate;
      this.handleChangePage(1);
    },

    async retrieveInvoices() {
      setQueryParams({ page: this.currentPage, ...this.currentFilteringData });
      const response = await AdminService.getInvoices(this.currentPage, this.currentFilteringData);
      if (!response?.success) return;

      this.invoices = response.data.invoices;
      this.filters = response.data.filters;
      this.billAmount = response.data.billAmount;
      this.statementAmount = response.data.statementAmount;
      this.ibanMissing = response.data.ibanMissing;
      this.totalCount = response.data.page.totalCount;
      this.totalPages = response.data.page.totalPages;
    },

    redirectToInvoiceDetailsPage(invoiceId) {
      this.$router.push(`/admin/invoices/${invoiceId}`);
    },

    initQueryParams() {
      const queryParams = getQueryParams();
      this.currentFilteringData = {
        organizations: arrayFilterIfPresent(queryParams.organizations),
        stores: arrayFilterIfPresent(queryParams.stores),
        payment_statuses: arrayFilterIfPresent(queryParams.payment_statuses),
        transaction_types: arrayFilterIfPresent(queryParams.transaction_types),
        start_date: queryParams.start_date,
        end_date: queryParams.end_date,
      };
      this.currentPage = queryParams.page || 1;
    },

    async exportSummary() {
      const paymentStartDate = this.currentFilteringData["payment_start_date"];
      const paymentEndDate = this.currentFilteringData["payment_end_date"];

      const params = { paymentStartDate, paymentEndDate };

      const response = await AdminService.getInvoicesSummary(params);
      if (!response?.success) return;

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      const filename =
        paymentStartDate && paymentEndDate
          ? `invoices_summary_${this.$d(new Date(paymentStartDate), "long")}_${this.$d(new Date(paymentEndDate), "long")}.csv`
          : `invoices_summary.csv`;

      link.setAttribute("download", filename);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      this.notify({
        category: "simple",
        type: "success",
        title: "Exporting invoices summary file.",
      });
    },
  },
};
</script>
