<template>
  <Modal :open="openModal" @close="closeEditModal">
    <h1 class="text-center font-black text-xl">Modifier vos informations</h1>

    <div class="my-10">
      <label for="iban" class="block text-sm font-medium text-gray-700">
        {{ $t("Iban") }}
      </label>
      <FormField name="iban" v-model="formData.iban" id="iban" />
    </div>

    <div class="my-10">
      <label for="billing_address" class="block text-sm font-medium text-gray-700">
        {{ $t("Billing address") }}
      </label>
      <FormField name="billing_address" v-model="formData.billingAddress" id="billing_address" />
    </div>

    <div class="flex justify-center">
      <button
        type="button"
        class="inline-flex items-center rounded-2xl border border-transparent text-button-font-primary bg-primary uppercase px-6 py-3 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
        @click="update"
      >
        Mettre à jour
      </button>
    </div>
  </Modal>
</template>

<script>
import { Modal } from "@estaly/ui";
import { FormField } from "@estaly/ui";

export default {
  components: {
    Modal,
    FormField,
  },

  props: ["openModal", "organization"],

  data() {
    return {
      formData: {
        iban: this.organization.iban,
        billingAddress: this.organization.billingAddress,
      },
    };
  },

  methods: {
    closeEditModal() {
      this.$emit("closeEditModal");
    },

    update() {
      this.$emit("update", this.formData);
    },
  },
};
</script>
