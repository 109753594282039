<template>
  <div>
    <div class="px-2 my-4 flex justify-between items-center">
      <h1 class="text-4xl font-heading-1 tracking-tight text-gray-900">
        {{ $t("Retributions") }}
      </h1>

      <GenericButton @click="retributionsModalOpen = true">Grille de primes</GenericButton>
    </div>

    <TabsNavigation :tabs="tabs" class="mb-6" />

    <Modal size="big" :open="retributionsModalOpen" @close="retributionsModalOpen = false">
      <div class="flex justify-between items-center my-5">
        <h4 class="text-xl font-heading-1">Grille de primes</h4>
        <GenericButton @click="() => downloadRetributionsPdf(currentStoreId)">Exporter</GenericButton>
      </div>

      <Loading v-if="loadingRetributions" />
      <RetributionTable v-else :retributions="retributions" hide-store-remuneration />
    </Modal>

    <div class="my-5 px-4 py-2 bg-white shadow rounded-lg">
      <Selector
        id="store"
        label="Magasin"
        name="store"
        required
        v-model="currentStoreId"
        :options="storeOptions"
        class="mb-5"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { Loading, Modal, TabsNavigation, Selector } from "@estaly/ui";
import GenericButton from "@/components/utils/GenericButton.vue";
import RetributionTable from "@/components/retribution/RetributionTable.vue";

export default {
  components: {
    Selector,
    TabsNavigation,
    GenericButton,
    Modal,
    RetributionTable,
    Loading,
  },

  data() {
    return {
      retributionsModalOpen: false,
      tabs: [
        {
          name: "seller-retributions",
          path: "/admin/seller-retributions",
          displayName: "Suivi des primes",
        },
        {
          name: "retributions",
          path: "/admin/retributions",
          displayName: "Configuration",
        },
      ],
    };
  },

  created() {
    this.currentStoreId = this.$route.query.storeId || this.selectedStoreId || this.filteredStores[0]?.id;
  },

  computed: {
    ...mapState("store", ["stores"]),
    ...mapState("retributions", ["selectedStoreId", "retributions", "loadingRetributions"]),

    currentStoreId: {
      get() {
        return this.selectedStoreId;
      },
      async set(value) {
        this.setSelectedStoreId(value);

        const url = new URL(location);
        url.searchParams.set("storeId", value);
        history.pushState({}, "", url);

        await this.fetchRetributions();
      },
    },

    filteredStores() {
      return this.stores.filter((store) => store.source === "offline" && store.status === "active");
    },

    storeOptions() {
      return this.filteredStores.map((store) => ({
        label: store.salesChannel,
        value: store.id,
      }));
    },

    store() {
      return this.stores.find((store) => store.id == this.selectedStoreId);
    },
  },

  methods: {
    ...mapActions("retributions", ["fetchRetributions", "downloadRetributionsPdf"]),
    ...mapMutations("retributions", ["setSelectedStoreId"]),
  },

  watch: {
    async retributionsModalOpen(open) {
      if (open && !this.retributions.length) {
        await this.fetchRetributions();
      }
    },
  },
};
</script>
