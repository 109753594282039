<template>
  <div class="mt-2 bg-white p-4 rounded-md">
    <p class="text-xl font-heading-1 pb-2 tracking-tight text-gray-900">
      {{ $t("Client informations") }}
    </p>
    <div class="grid grid-cols-2 gap-x-4 gap-y-3">
      <div class="flex-1">
        <label for="firstName" class="block text-sm text-gray-700">
          {{ $t("First Name") }}
          <span class="ml-1 text-red-600">*</span>
        </label>
        <Field
          type="text"
          name="customer.firstName"
          id="firstName"
          autocomplete="given-name"
          class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
          placeholder="John"
          v-model="localCustomer.firstName"
          @keydown="blockEnterBehavior"
        />
        <ErrorMessage name="customer.firstName" class="mt-2 text-sm text-red-600" />
      </div>

      <div class="flex-1">
        <label for="lastName" class="block text-sm text-gray-700">
          {{ $t("Last Name") }}
          <span class="ml-1 text-red-600">*</span>
        </label>
        <Field
          type="text"
          name="customer.lastName"
          id="lastName"
          autocomplete="family-name"
          class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
          placeholder="Doe"
          v-model="localCustomer.lastName"
          @keydown="blockEnterBehavior"
        />
        <ErrorMessage name="customer.lastName" class="mt-2 text-sm text-red-600" />
      </div>

      <div class="flex-1">
        <label for="email" class="block text-sm text-gray-700">
          {{ $t("Customer email") }}
          <span class="ml-1 text-red-600">*</span>
        </label>
        <Field
          id="email"
          name="customer.email"
          type="email"
          class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
          placeholder="customer@gmail.com"
          v-model="localCustomer.email"
          @keydown="blockEnterBehavior"
        />
        <ErrorMessage name="customer.email" class="mt-2 text-sm text-red-600" />
      </div>

      <div class="flex-1">
        <label for="phone" class="block text-sm text-gray-700">
          {{ $t("Customer phone number") }}
          <span class="ml-1 text-red-600">*</span>
        </label>
        <Field
          id="phone"
          name="customer.phone"
          type="tel"
          class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
          placeholder="+33 6 99 49 75 90"
          v-model="localCustomer.phone"
          @keydown="blockEnterBehavior"
        />
        <ErrorMessage name="customer.phone" class="mt-2 text-sm text-red-600" />
      </div>
    </div>
  </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";

export default {
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    modelValue: Object,
  },
  inject: ["validationContext"],
  computed: {
    localCustomer: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  mounted() {
    this.validationContext?.validateField("customer.firstName");
    this.validationContext?.validateField("customer.lastName");
    this.validationContext?.validateField("customer.email");
    this.validationContext?.validateField("customer.phone");
  },
  methods: {
    blockEnterBehavior(event) {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
      }
    },
  },
};
</script>
