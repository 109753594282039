import AuthService from "@/api/services/auth";
import UserService from "@/api/services/user";
import { isAdmin, isSeller, isResponsible } from "@/utils/user_roles";
import { isPresent } from "@/utils/validation";

export default {
  namespaced: true,
  state: {
    currentUser: {},
    signedIn: false,
    csrf: null,
  },

  getters: {
    isStore: (state) => isAdmin(state.currentUser) || state.currentUser.role === "storemanager",
    isStoreOwner: (state) => state.currentUser.role === "storemanager",
    isAdmin: (state) => isAdmin(state.currentUser),
    isOrganizationManager: (state) => state.currentUser.role === "organizationmanager",
    isCustomer: (state) => state.currentUser.role === "customer",
    isOffline: (state) => state.currentUser.storeSource === "offline",
    isOnline: (state) => state.currentUser.storeSource === "online",
    isSeller: (state) => isSeller(state.currentUser),
    isSellerOrResponsible: (state) => isSeller(state.currentUser) || isResponsible(state.currentUser),
    isResponsible: (state) => isResponsible(state.currentUser),

    currentUserHasPermission: (state) => (permission) => {
      return isPresent(state.currentUser?.permissions) ? state.currentUser.permissions.includes(permission) : false;
    },
  },

  mutations: {
    setCurrentUser(state, { currentUser, csrf }) {
      state.currentUser = currentUser;
      state.signedIn = true;
      state.csrf = csrf;
    },

    unsetCurrentUser(state) {
      state.currentUser = {};
      state.signedIn = false;
      state.csrf = null;
    },

    refresh(state, csrf) {
      state.csrf = csrf;
      state.signedIn = true;
    },
  },

  actions: {
    async signin({ dispatch }, user) {
      const response = await AuthService.signin(user);
      if (!response?.success) return response;

      await dispatch("postSignIn", response);
      return response;
    },

    async signup({ dispatch }, params) {
      const response = await AuthService.signup(params);
      if (!response?.success) return response;

      await dispatch("postSignIn", response);
      return response;
    },

    async resetPassword({ dispatch }, { resetPasswordToken, payload }) {
      const response = await AuthService.resetPassword(resetPasswordToken, payload);
      if (!response?.success) return response;

      await dispatch("postSignIn", response);
      return response;
    },

    async postSignIn(context, response) {
      const csrf = response.data.csrf;
      if (csrf) {
        const resp = await UserService.getUser();
        const currentUser = resp.data;
        context.commit("setCurrentUser", { currentUser, csrf });
        if (context.getters["isSellerOrResponsible"]) {
          context.dispatch("store/updateStoreId", currentUser.storeIds[0], { root: true });
          await context.dispatch("store/fetchActiveStores", null, { root: true });
        }
        context.dispatch("notifications/clearAll", null, { root: true });
      } else {
        context.commit("unsetCurrentUser");
      }
    },

    async refreshCurrentUser({ commit, state }) {
      const response = await UserService.getUser();
      if (!response?.success) return response;

      const currentUser = response.data;
      const csrf = state.csrf;
      commit("setCurrentUser", { currentUser, csrf });
      return response;
    },

    async signout({ commit }) {
      const response = await AuthService.signout();
      if (!response?.success) return response;

      commit("unsetCurrentUser");
      localStorage.clear();
      sessionStorage.clear();
      window.location.replace("/signin");
    },
  },
};
