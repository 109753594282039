<template>
  <div>
    <Header />

    <Alert class="mb-5">
      <template #title>Configurer le système de primes vendeurs</template>
      <p class="text-sm">
        Afin de motiver vos vendeurs à proposer les services d'Estaly, vous avez la possibilité de leur rétribuer une
        partie de votre rémunération. Une fois les primes configurées, vous pouvez suivre le montant à reverser mois par
        mois à chacun de vos vendeurs depuis l'onglet "Mes relevés" en cliquant sur le détail de relevé
      </p>
    </Alert>

    <template v-if="store">
      <div class="mb-5">
        <h3 class="text-2xl font-heading-1 mb-5">
          Configurer le système de primes vendeurs : {{ store.salesChannel }}
        </h3>
        <div class="grid grid-cols-2 gap-5">
          <div class="shadow-lg py-5 px-7 flex flex-col justify-between">
            <h4 class="text-xl font-heading-1 mb-2">Montant fixe par offre</h4>
            <p>Pour chaque offre, indiquez le montant que vous souhaitez rétribuer à vos vendeurs.</p>
            <div class="text-center m-2">
              <GenericButton @click="configFixedPriceModalOpen = true">Configurer un montant fixe</GenericButton>
            </div>
            <Modal size="big" :open="configFixedPriceModalOpen" @close="configFixedPriceModalOpen = false">
              <h4 class="text-xl font-heading-1">Rétribution en montant fixe</h4>
              <p>Indiquer le montant de la rétribution par offre et par durée.</p>

              <RetributionTable :retributions="retributions" editable @editRetribution="editRetribution" />
              <div class="mt-4 flex justify-center">
                <GenericButton @onClick="submitEditFixedPrice">Valider la rétribution</GenericButton>
              </div>
            </Modal>
          </div>

          <div class="shadow-lg py-5 px-7 flex flex-col justify-between">
            <h4 class="text-xl font-heading-1 mb-2">Primes en pourcentage</h4>
            <p>Indiquez le montant en pourcentage de votre rémunération que vous souhaitez rétribuer.</p>
            <div class="text-center m-2">
              <GenericButton @click="configPercentageModalOpen = true">Configurer un pourcentage</GenericButton>
            </div>
            <Modal :open="configPercentageModalOpen" @close="configPercentageModalOpen = false">
              <h4 class="text-xl font-heading-1">Rétribution en pourcentage</h4>
              <p class="mb-4">Indiquez le pourcentage de la rémunération que vous souhaitez rétribuer aux vendeurs.</p>
              <FormField
                id="percentage_value"
                label="Pourcentage de la rémunération à rétribuer"
                name="percentage_value"
                trailing-add-ons="%"
                required
                v-model="percentageValue"
              />
              <div class="mt-4 flex justify-center">
                <GenericButton @onClick="submitEditPercentage">Valider la rétribution</GenericButton>
              </div>
            </Modal>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <div class="flex justify-between items-center mb-5">
          <h3 class="text-2xl font-heading-1">Grille de rétribution</h3>
          <GenericButton @click="() => downloadRetributionsPdf(store.id)">Exporter</GenericButton>
        </div>
        <RetributionTable :retributions="retributions" />
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Alert } from "@estaly/ui";
import { Modal } from "@estaly/ui";
import { FormField } from "@estaly/ui";
import GenericButton from "@/components/utils/GenericButton.vue";
import RetributionTable from "@/components/retribution/RetributionTable.vue";
import StoreService from "@/api/services/store.js";
import Header from "@/components/admin/retributions/Header.vue";

export default {
  components: {
    Header,
    FormField,
    Alert,
    Modal,
    GenericButton,
    RetributionTable,
  },

  data() {
    return {
      configPercentageModalOpen: false,
      configFixedPriceModalOpen: false,
      percentageValue: null,
      retributionsFixedPriceChanges: {},
    };
  },

  computed: {
    ...mapState("store", ["stores"]),
    ...mapState("retributions", ["selectedStoreId", "retributions"]),

    filteredStores() {
      return this.stores.filter((store) => store.source === "offline" && store.status === "active");
    },

    store() {
      return this.stores.find((store) => store.id == this.selectedStoreId);
    },
  },

  methods: {
    ...mapActions("retributions", ["fetchRetributions", "downloadRetributionsPdf"]),
    ...mapActions("notifications", ["notify", "clearAll"]),

    async submitEditPercentage() {
      if (!this.percentageValue) return document.getElementById("percentage_value").focus();

      const response = await StoreService.updateRetributionsPercentage(this.store.id, {
        percentage_value: this.percentageValue / 100,
      });
      if (!response.success) return;

      await this.fetchRetributions();
      await this.notify({
        category: "simple",
        type: "success",
        title: "Mise à jour effectuée",
      });
      this.configPercentageModalOpen = false;
    },

    editRetribution(retributionId, value) {
      this.retributionsFixedPriceChanges[retributionId] = value;
    },

    async submitEditFixedPrice() {
      this.clearAll();
      const errors = [];

      await Promise.all(
        Object.entries(this.retributionsFixedPriceChanges).map(async ([retributionId, value]) => {
          const response = await StoreService.updateRetributionsFixedPrice(this.store.id, retributionId, {
            fixed_value: value,
          });
          if (!response.success) errors.push(retributionId);
          else {
            this.lowlightError(retributionId);
            delete this.retributionsFixedPriceChanges[retributionId];
          }
        }),
      );

      await this.fetchRetributions();

      if (errors.length === 0) {
        await this.notify({
          category: "simple",
          type: "success",
          title: "Mise à jour effectuée",
        });
        this.configFixedPriceModalOpen = false;
      } else {
        errors.forEach((retributionId) => {
          this.highlightError(retributionId);
        });
      }
    },

    highlightError(retributionId) {
      document.getElementById(`retribution-${retributionId}`).classList.add("border-2", "border-red-500");
    },

    lowlightError(retributionId) {
      document.getElementById(`retribution-${retributionId}`).classList.remove("border-2", "border-red-500");
    },
  },

  watch: {
    async selectedStoreId() {
      if (this.store?.sellerIncentivePercentage) this.percentageValue = this.store.sellerIncentivePercentage * 100;
    },
  },
};
</script>
