import { createStore } from "vuex";
import auth from "./modules/auth";
import notifications from "./modules/notifications";
import store from "./modules/store";
import VuexPersistence from "vuex-persist";
import claims from "@/store/modules/claims";
import navigation from "@/store/modules/navigation";
import plan_sale_list from "@/store/modules/plan_sale_list";
import claim_list from "@/store/modules/claim_list";
import retributions from "@/store/modules/retributions";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default createStore({
  modules: {
    auth,
    notifications,
    store,
    claims,
    navigation,
    plan_sale_list,
    claim_list,
    retributions,
  },
  plugins: [vuexLocal.plugin],
});
