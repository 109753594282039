<template>
  <div v-if="planSale">
    <div class="flex flex-row justify-between">
      <div class="block">
        <h1 class="text-4xl font-heading-1 pl-2 tracking-tight text-gray-900 my-4">
          {{ $t("Plan Sale details") }}
        </h1>
        <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
          {{ $t("Protection details") }}
          <span class="font-bold">{{ planSale.id }}</span>
        </p>
      </div>
      <div v-if="planSaleActive" class="flex flex-row gap-2">
        <div v-if="planSaleCancelAllowed">
          <GenericButton @click="openCancelModal = true">
            {{ $t("Cancel") }}
          </GenericButton>
          <CancellationModal
            v-bind:open="openCancelModal"
            @close="openCancelModal = false"
            @cancelPlanSale="cancelPlanSale"
          />
        </div>
        <div v-if="planSaleUpdateAllowed">
          <GenericButton @click="openEditModal = true">
            {{ $t("Update") }}
          </GenericButton>
          <UpdateModal
            :open="openEditModal"
            :planSale="planSale"
            @closeUpdateModal="openEditModal = false"
            @updatePlanSale="updatePlanSale"
          />
        </div>
      </div>
    </div>

    <div class="mt-4">
      <CancellationDetails :cancellation="planSale.cancellation" v-if="planSale.cancellation" />
    </div>

    <div class="mt-4">
      <div class="mt-2 border-t border-gray-100 bg-white px-4 sm:px-6 py-5">
        <h2 class="col-span-2 text-xl font-bold mb-4">{{ $t("Customer") }}</h2>
        <dl class="grid grid-cols-2 gap-x-4 gap-y-6">
          <div>
            <dt class="text-sm font-bold text-gray-500">{{ $t("First Name") }} {{ $t("Last Name") }}</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.customer.firstName }}
              {{ planSale.customer.lastName }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Email address") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.customer.email }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">{{ $t("Address") }}</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <p v-if="planSale.customer.address1">
                {{ planSale.customer.address1 }}
              </p>
              <p class="font-bold" v-else>—</p>
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Phone number") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.customer.phone }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Birth date") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.customer.birthDate }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Birth city") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ customerBirthAddress }}
            </dd>
          </div>
          <div v-if="planSale.orderReferenceId">
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Order ID") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.orderReferenceId }}
            </dd>
          </div>
        </dl>
      </div>
    </div>

    <div class="mt-6">
      <div class="mt-2 border-t border-gray-100 bg-white px-4 sm:px-6 py-5">
        <h2 class="col-span-2 text-xl font-bold mb-4">{{ $t("Insurance") }}</h2>
        <dl class="grid grid-cols-2 gap-x-4 gap-y-6">
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Insurance Plan") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.planTitle }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Insurance Offer") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.category }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Warranty Price") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ formatPrice(planSale.price) }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">{{ $t("Payment") }}</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.monthlyBilling ? "Mensuel" : "Annuel" }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Purchase date") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.purchaseDate }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Contract start date") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.startDate }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Subscription form") }}
            </dt>
            <TextCopy
              class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 cursor-pointer underline-offset-4 hover:text-primary-dark"
              v-bind:textToCopy="planSale.membershipAgreement"
            >
              {{ $t("Copy link to clipboard") }}
            </TextCopy>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Charged by") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.chargedBy }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">{{ $t("Shop") }}</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.salesChannel }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">{{ $t("Seller") }}</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <p v-if="sellerName">{{ sellerName }}</p>
              <p class="font-bold" v-else>—</p>
            </dd>
          </div>
        </dl>
      </div>
    </div>

    <ProductsDetails :plan-sale="planSale" />

    <PlanSaleBillingsDetails
      v-if="isAdminPage"
      :plan-sale-id="planSaleId"
      :plan-sale-billings="planSale.planSaleBillings"
    />
  </div>
  <div v-else-if="!isLoading" class="text-center flex h-screen">
    <div class="m-auto">
      <p>{{ $t("Something went wrong.") }}</p>
      <GenericButton @onClick="goBackToPlanSalesList" class="mt-4">
        {{ $t("Back") }}
      </GenericButton>
    </div>
  </div>
</template>

<script>
import AdminService from "@/api/services/admin";
import StoreService from "@/api/services/store";
import TextCopy from "@/components/utils/TextCopy.vue";
import ProductsDetails from "@/views/admin/plansale/details/ProductsDetails.vue";
import PlanSaleBillingsDetails from "@/views/admin/plansale/details/PlanSaleBillingsDetails.vue";
import { formatPrice } from "@estaly/ui/src/utils/price_formatter";
import { mapActions, mapMutations, mapGetters } from "vuex";
import GenericButton from "@/components/utils/GenericButton.vue";
import CancellationDetails from "@/views/admin/plansale/details/CancellationDetails.vue";
import UpdateModal from "@/views/admin/plansale/details/UpdateModal.vue";
import CancellationModal from "@/views/admin/plansale/details/CancellationModal.vue";

export default {
  components: {
    CancellationModal,
    UpdateModal,
    GenericButton,
    PlanSaleBillingsDetails,
    ProductsDetails,
    TextCopy,
    CancellationDetails,
  },

  data() {
    return {
      planSale: null,
      planSaleId: this.$route.params.id,
      planSaleUpdateAllowed: false,
      planSaleCancelAllowed: false,
      openEditModal: false,
      openCancelModal: false,
    };
  },

  async mounted() {
    await this.getPlanSale();
  },

  computed: {
    ...mapGetters("store", ["isLoading", "selectedStoreId"]),
    ...mapGetters("navigation", ["isAdminPage"]),

    customerBirthAddress() {
      const birthInfo = [this.planSale.customer.birthCity, this.planSale.customer.birthCountry];
      return birthInfo.filter(Boolean).join(", ");
    },

    sellerName() {
      const sellerInfo = [this.planSale.seller?.first_name, this.planSale.seller?.last_name];
      return sellerInfo.filter(Boolean).join(" ");
    },

    planSaleActive() {
      return this.planSale.status === "active";
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    ...mapMutations("store", ["setIsLoading"]),

    formatPrice,

    async getPlanSale() {
      this.setIsLoading(true);
      const response = this.isAdminPage
        ? await AdminService.getPlanSale(this.planSaleId)
        : await StoreService.getPlanSale(this.planSaleId, this.selectedStoreId);
      if (response?.success) {
        this.planSale = response.data;
        this.planSaleUpdateAllowed = this.planSale.planSaleUpdateAllowed;
        this.planSaleCancelAllowed = this.planSale.planSaleCancelAllowed;
      }
      this.setIsLoading(false);
    },

    goBackToPlanSalesList() {
      const currentRoute = this.$route.fullPath;
      const baseRoute = currentRoute.split("/").slice(0, -1).join("/");
      this.$router.push(baseRoute);
    },

    async cancelPlanSale(reason, message) {
      this.setIsLoading(true);
      const formData = {
        plan_sale: {
          cancellation_reason: reason,
          feedback_message: message,
        },
      };

      let response;
      if (this.isAdminPage) {
        response = await AdminService.cancelPlanSale(this.planSaleId, formData);
      } else {
        response = await StoreService.cancelPlanSale(this.planSaleId, this.selectedStoreId, formData);
      }
      if (response?.success) {
        this.openCancelModal = false;
        await this.getPlanSale();
        await this.notify({
          category: "simple",
          type: "success",
          text: "Contract cancelled",
        });
      }
      this.setIsLoading(false);
    },

    async updatePlanSale(planSaleInfo) {
      this.setIsLoading(true);
      const formData = {
        plan_sale: planSaleInfo,
      };
      let response;
      if (this.isAdminPage) {
        response = await AdminService.updatePlanSale(this.planSaleId, formData);
      } else {
        response = await StoreService.updatePlanSale(this.planSaleId, this.selectedStoreId, formData);
      }
      if (response?.success) {
        this.openEditModal = false;
        await this.getPlanSale();
        await this.notify({
          category: "simple",
          type: "success",
          text: "Contract updated",
        });
      }
      this.setIsLoading(false);
    },
  },
};
</script>
