import { securedAxiosInstance, plainAxiosInstance } from "../axios";
import { toSnakeCase } from "@estaly/ui/src/utils/data_formatter";

class AnalyticsService {
  getRetailStoresData(startDate, endDate, sortParams) {
    return securedAxiosInstance.get("/admin/analytics/retail/stores", {
      params: {
        start: startDate,
        end: endDate,
        sortColumn: sortParams["column"],
        sortOrder: sortParams["order"],
      },
    });
  }

  getRetailSellersData(storeId, startDate, endDate, sortParams) {
    return securedAxiosInstance.get("/admin/analytics/retail/sellers", {
      params: {
        start: startDate,
        end: endDate,
        store_id: storeId,
        sortColumn: sortParams["column"],
        sortOrder: sortParams["order"],
      },
    });
  }

  getGeneralAnalytics({ startDate, endDate, stores, comparisonType }) {
    return securedAxiosInstance.get("/admin/analytics", {
      params: {
        start_date: startDate,
        end_date: endDate,
        stores,
        comparison_type: comparisonType,
      },
    });
  }

  computeOrganizationInvoicesAnalytics(params) {
    return plainAxiosInstance.get("/analytics/invoices", {
      params: toSnakeCase(params),
    });
  }

  computeOrganizationCommissionsAnalytics(params) {
    return plainAxiosInstance.get("/analytics/commissions", {
      params: toSnakeCase(params),
    });
  }

  computeOrganizationClaimsAnalytics(params) {
    return plainAxiosInstance.get("/analytics/claims", {
      params: toSnakeCase(params),
    });
  }
}

export default new AnalyticsService();
