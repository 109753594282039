<template>
  <div>
    <Header />
    <Tabs :tabs="tabs" @tab-selected="updateStatus" />

    <SellerRetributionsRanking :status="status" />
  </div>
</template>

<script>
import { Tabs } from "@estaly/ui";
import SellerRetributionsRanking from "@/components/pos/retributions/SellerRetributionsRanking.vue";

import Header from "@/components/admin/retributions/Header.vue";

export default {
  components: {
    Header,
    Tabs,
    SellerRetributionsRanking,
  },

  data() {
    return {
      status: "pending",
      tabs: [
        {
          name: "pending",
          displayName: "Primes en attente",
          current: true,
        },
        {
          name: "paid",
          displayName: "Primes versées",
        },
      ],
    };
  },

  methods: {
    updateStatus(tab) {
      this.status = tab.name === "pending" ? "pending" : "paid";
    },
  },
};
</script>
