<template>
  <div>
    <div class="bg-white shadow sm:rounded-t-md">
      <!-- Mobile filter dialog -->
      <TransitionRoot as="template" :show="openFilters">
        <Dialog as="div" class="fixed inset-0 flex z-40 sm:hidden" v-on:close="openFilters = false">
          <TransitionChild
            as="template"
            enter="transition-opacity ease-linear duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>

          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div
              class="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-6 flex flex-col overflow-y-auto"
            >
              <div class="px-4 flex items-center justify-between">
                <h2 class="text-lg font-medium text-gray-900">Filters</h2>
                <button
                  type="button"
                  class="-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary"
                  v-on:click="openFilters = false"
                >
                  <span class="sr-only">Close menu</span>
                  <XIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              <!-- Filters -->
              <fieldset class="mt-4">
                <Disclosure as="div" class="px-4 py-6" v-slot="{ open }">
                  <h3 class="-mx-2 -my-3 flow-root">
                    <DisclosureButton
                      class="px-2 py-3 bg-white w-full flex items-center justify-between text-sm text-gray-400"
                    >
                      <span class="font-medium text-gray-900">
                        {{ section.name }}
                      </span>
                      <span class="ml-6 flex items-center">
                        <ChevronDownIcon
                          :class="[open ? '-rotate-180' : 'rotate-0', 'h-5 w-5 transform']"
                          aria-hidden="true"
                        />
                      </span>
                    </DisclosureButton>
                  </h3>
                  <DisclosurePanel class="pt-6">
                    <div class="space-y-6">
                      <div v-for="(option, optionIdx) in section.options" :key="option.value" class="flex items-center">
                        <input
                          :id="`filter-mobile-${section.id}-${optionIdx}`"
                          :name="`${section.id}[]`"
                          :value="option.value"
                          type="checkbox"
                          class="h-4 w-4 border-gray-300 rounded text-primary-dark focus:ring-primary"
                        />
                        <label :for="`filter-mobile-${section.id}-${optionIdx}`" class="ml-3 text-sm text-gray-500">
                          {{ option.label }}
                        </label>
                      </div>
                    </div>
                  </DisclosurePanel>
                </Disclosure>
              </fieldset>
            </div>
          </TransitionChild>
        </Dialog>
      </TransitionRoot>

      <div class="mt-2 mx-auto px-4 text-center sm:px-6 lg:px-8 divide-y divide-gray-400">
        <section aria-labelledby="filter-heading" class="py-6">
          <h2 id="filter-heading" class="sr-only">
            {{ this.title }}
          </h2>

          <div class="flex items-center justify-between">
            <div class="relative focus-within:z-10 flex-grow">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                type="text"
                name="mobile-search-bar"
                id="mobile-search-bar"
                class="focus:ring-primary focus:border-primary block w-full rounded-lg pl-10 sm:hidden border-gray-300"
                :placeholder="this.placeholder"
                v-model="searchContent"
              />
              <input
                type="text"
                name="desktop-search-bar"
                id="desktop-search-bar"
                class="hidden focus:ring-primary focus:border-primary w-full rounded-lg pl-10 sm:block sm:text-sm border-gray-300"
                :placeholder="this.placeholder"
                v-model="searchContent"
              />
            </div>

            <button
              type="button"
              class="inline-block text-sm font-medium text-gray-700 hover:text-gray-900 sm:hidden"
              @click="open = true"
            >
              {{ $t("Filters") }}
            </button>

            <PopoverGroup class="hidden sm:flex sm:items-baseline sm:space-x-8">
              <div
                class="ml-2 cursor-pointer inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                v-on:click="clear"
                v-show="isKeywordsInputNotEmpty"
              >
                {{ $t("Clear") }}
              </div>
              <SearchBarFilter
                v-for="(filterOptions, filterName, filterIdx) in availableFiltersWithOptions"
                :key="filterIdx"
                :filterName="filterName"
                :filterIdx="filterIdx"
                :current-page="this.currentPage"
                :available-filters="this.availableFilters"
                :selected-filters="this.selectedFilters"
                @updateFilterSearch="this.updateFilterSearch"
              />
            </PopoverGroup>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  PopoverGroup,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XIcon, SearchIcon } from "@heroicons/vue/outline";
import { ChevronDownIcon } from "@heroicons/vue/solid";
import { SearchBarFilter } from "@estaly/ui";
import { isPresent } from "@/utils/validation";
export default {
  components: {
    SearchBarFilter,
    Dialog,
    DialogOverlay,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    PopoverGroup,
    TransitionChild,
    TransitionRoot,
    ChevronDownIcon,
    XIcon,
    SearchIcon,
  },

  props: ["currentPage", "availableFilters", "selectedFilters", "placeholder", "title", "initialKeywords"],

  data() {
    return {
      openFilters: false,
      debounce: null,
      searchContent: null,
    };
  },

  mounted() {
    this.searchContent = this.initialKeywords;
  },

  watch: {
    initialKeywords(newValue) {
      this.searchContent = newValue;
    },

    searchContent(newValue) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.$emit("updateKeywordSearch", newValue);
      }, 500);
    },
  },

  computed: {
    availableFiltersWithOptions() {
      return Object.fromEntries(
        Object.entries(this.availableFilters).filter(
          ([filterKey, filterOptions]) => filterKey && filterOptions?.length > 1,
        ),
      );
    },

    isKeywordsInputNotEmpty() {
      return isPresent(this.searchContent);
    },
  },

  methods: {
    clear() {
      this.searchContent = "";
    },

    updateFilterSearch(filterName, filterOptions) {
      this.$emit("updateFilterSearch", filterName, filterOptions);
    },
  },
};
</script>
