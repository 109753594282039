<template>
  <div>
    <div class="bg-white p-4 rounded-md mt-4">
      <p class="text-xl font-heading-1 tracking-tight text-gray-900">Souscription</p>
      <div>
        <button
          type="submit"
          class="mt-2 w-full py-2 px-4 border border-transparent shadow-sm text-lg font-medium text-button-font-secondary bg-secondary transition duration-300 transform hover:scale-105 rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
          @click="$emit('sendTextMessage')"
        >
          <div class="flex align-center justify-center">
            <div class="flex justify-center flex-col">
              <MailOpenIcon class="h-5 w-5 mr-2" />
            </div>
            <p class="uppercase font-body-bold">Envoyer un devis</p>
          </div>
        </button>
        <button
          type="submit"
          class="mt-2 w-full justify-center py-2 px-4 border border-transparent shadow-sm text-lg font-medium text-button-font-primary bg-primary transition duration-300 transform hover:scale-105 rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          @click="$emit('paymentInScreen')"
        >
          <div class="flex align-center justify-center">
            <div class="flex justify-center flex-col">
              <DesktopComputerIcon class="h-5 w-5 mr-2" />
            </div>
            <p class="uppercase">Payer sur cet écran</p>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { MailOpenIcon, DesktopComputerIcon } from "@heroicons/vue/outline";

export default {
  components: {
    MailOpenIcon,
    DesktopComputerIcon,
  },

  emits: ["sendTextMessage", "paymentInScreen"],
};
</script>
