import { startOfMonth, endOfMonth, subMonths, startOfWeek, endOfWeek, subWeeks, subDays, startOfYear, format } from "date-fns";

export function sameDay(date1, date2) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

export function currentMonth(startDate, endDate) {
  const now = new Date();
  return sameDay(startDate, startOfMonth(now)) && sameDay(endDate, now);
}

export function previousMonth(startDate, endDate) {
  const now = new Date();
  const lastMonthStart = startOfMonth(subMonths(now, 1));
  const lastMonthEnd = endOfMonth(subMonths(now, 1));
  return sameDay(startDate, lastMonthStart) && sameDay(endDate, lastMonthEnd);
}

export function currentWeek(startDate, endDate) {
  const now = new Date();
  return (
    sameDay(startDate, startOfWeek(now, { weekStartsOn: 1 })) && sameDay(endDate, now)
  );
}

export function previousWeek(startDate, endDate) {
  const now = new Date();
  const lastWeekStart = subWeeks(startOfWeek(now, { weekStartsOn: 1 }), 1);
  const lastWeekEnd = subWeeks(endOfWeek(now, { weekStartsOn: 1 }), 1);
  return sameDay(startDate, lastWeekStart) && sameDay(endDate, lastWeekEnd);
}

export function currentMonthRange() {
  const now = new Date();
  return [startOfMonth(now), now];
}

export function previousMonthRange() {
  return [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))];
}

export function currentWeekRange() {
  return [startOfWeek(new Date(), { weekStartsOn: 1 }), new Date()];
}

export function previousWeekRange() {
  return [
    subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 1),
    subWeeks(endOfWeek(new Date(), { weekStartsOn: 1 }), 1),
  ];
}

export function last7days(startDate, endDate) {
  const now = new Date();
  return sameDay(startDate, subDays(now, 6)) && sameDay(endDate, now);
}

export function last7daysRange() {
  return [subDays(new Date(), 6), new Date()];
}

export function last4weeks(startDate, endDate) {
  const now = new Date();
  return sameDay(startDate, subWeeks(now, 4)) && sameDay(endDate, now);
}

export function last4weeksRange() {
  return [subWeeks(new Date(), 4), new Date()];
}

export function last3months(startDate, endDate) {
  const now = new Date();
  return sameDay(startDate, subMonths(now, 3)) && sameDay(endDate, now);
}

export function last3monthsRange() {
  return [subMonths(new Date(), 3), new Date()];
}

export function currentYear(startDate, endDate) {
  const now = new Date();
  return sameDay(startDate, startOfYear(now)) && sameDay(endDate, now);
}

export function currentYearRange() {
  return [startOfYear(new Date()), new Date()];
}

export function inputDateFormat(date) {
  return format(date, "yyyy-MM-dd");
}
