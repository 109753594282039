<template>
  <div>
    <div>
      <FormField
        id="businessName"
        label="Raison sociale du magasin"
        labelClass="font-normal"
        name="businessName"
        required
        placeholder="Raison sociale"
        v-model="formData.businessName"
      />
      <FormField
        id="salesChannel"
        label="Nom commercial du magasin"
        labelClass="font-normal"
        name="salesChannel"
        required
        placeholder="Nom commercial"
        v-model="formData.salesChannel"
      />
      <FormField
        id="billingAddress"
        label="Adresse de facturation du magasin"
        labelClass="font-normal"
        name="billingAddress"
        required
        placeholder="Adresse de facturation"
        v-model="formData.billingAddress"
      />
      <LocationField
        id="shippingAddress"
        label="Adresse postale du magasin"
        placeholder="Adresse postale"
        v-model="formData.shippingAddress"
        required
      />
      <Selector
        id="revenue_range"
        label="Revenue range"
        labelClass="font-normal"
        name="revenue_range"
        required
        v-model="formData.revenueRange"
        :options="revenueRanges"
      />
    </div>
    <div class="mt-4 flex justify-end">
      <GenericButton @click="updateGeneralInformations">
        <span v-if="store[configStatus] == 'pending_validation'">Modifier</span>
        <span v-else>Valider</span>
      </GenericButton>
    </div>
  </div>
</template>

<script>
import GenericButton from "@/components/utils/GenericButton.vue";
import { Selector } from "@estaly/ui";
import { FormField } from "@estaly/ui";
import StoreService from "@/api/services/store";
import { LocationField } from "@estaly/ui";
import { mapActions } from "vuex";

export default {
  props: ["store", "configStatus"],

  components: {
    GenericButton,
    Selector,
    FormField,
    LocationField,
  },

  data() {
    return {
      formData: {
        businessName: this.store.businessName || "",
        salesChannel: this.store.salesChannel || "",
        billingAddress: this.store.billingAddress || "",
        shippingAddress: this.store.shippingAddress || "",
        revenueRange: this.store.revenueRange || "",
      },
      revenueRanges: [],
    };
  },

  created() {
    this.fetchOptions();
  },

  emits: ["configurationUpdated"],

  methods: {
    ...mapActions("notifications", ["notify"]),
    async fetchOptions() {
      const storeId = this.store.id;
      const response = await StoreService.getStoreGeneralInformationsOptions(storeId);
      if (response?.success) this.revenueRanges = response.data.revenue_ranges;
    },
    async updateGeneralInformations() {
      const storeId = this.store.id;
      const payload = {
        ...this.formData,
      };
      const response = await StoreService.updateStoreGeneralInformations(storeId, payload);
      if (!response?.success) return;

      this.$emit("configurationUpdated");
      this.$emit("stepValidated");
      this.notify({
        category: "simple",
        type: "success",
        title: "Informations mises à jour",
        text: "Les informations générales ont été mises à jour avec succès",
      });
    },
    deduplicate(options) {
      return [...new Map(options.map((option) => [option.value, option])).values()];
    },
  },
};
</script>
