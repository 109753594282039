<template>
  <div>
    <Loading v-if="isLoadingPeriods" />
    <Alert v-else-if="periodOptions.length == 0">{{ $t("no_data") }}</Alert>
    <div v-else class="flex justify-between">
      <template v-if="status === 'pending'">
        <Selector
          id="period"
          label="Période de vente"
          name="period"
          v-model="selectedPeriod"
          @update:model-value="updatePeriod"
          :options="periodOptions"
        />
        <div v-if="retributionDate">
          <span class="mt-2 block text-sm leading-6 text-gray-900 font-semibold">Date de versement estimée</span>
          <span class="mt-2 block text-gray-900 sm:text-sm sm:leading-6">
            {{ $d(retributionDate, "monthly") }}
          </span>
        </div>
      </template>
      <Selector
        v-else
        id="period"
        label="Date de versement"
        name="period"
        v-model="selectedPeriod"
        @update:model-value="updatePeriod"
        :options="periodOptions"
      />
    </div>

    <div v-if="selectedPeriod" class="mt-10">
      <Loading v-if="isLoadingSellersRetributionsRanking" />
      <Table v-else :columns="columns" :data="rankingData" is-accessible @view="openSellerRetributionsModal" />
      <Modal :open="sellerRetributionsModalOpen" @close="sellerRetributionsModalOpen = false">
        <i18n-t keypath="seller-retributions.modal-header" tag="h4" class="text-xl font-heading-1">
          <template #status>{{ $t(`seller-retributions.status.${status}`) }}</template>
          <template #name>{{ this.rowSelected.sellerName }}</template>
          <template #period>{{ formattedSelectedPeriod }}</template>
        </i18n-t>
        <Table class="mt-10" :columns="detailsColumns" :data="sellerRetributionsData">
          <template #planSaleCustomId="{ row }">
            <a
              target="_blank"
              class="text-blue-400 underline cursor-pointer"
              :href="
                isAdminPage ? `/admin/plan-sales/${row.planSaleCustomId}` : `/pos/plan-sales/${row.planSaleCustomId}`
              "
            >
              {{ row.planSaleCustomId }}
            </a>
          </template>
          <template #purchasedAt="{ row }">
            <span>{{ $d(new Date(row.purchasedAt)) }}</span>
          </template>
          <template #paymentDate="{ row }">
            <span>{{ row.paymentDate ? $d(new Date(row.paymentDate), "monthly") : "—" }}</span>
          </template>
        </Table>
      </Modal>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { Alert, Loading, Table, Modal, Selector } from "@estaly/ui";
import StoreService from "@/api/services/store.js";

export default {
  components: {
    Alert,
    Loading,
    Table,
    Modal,
    Selector,
  },

  props: {
    status: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isLoadingPeriods: false,
      selectedPeriod: null,
      periods: [],
      retributionDate: null,
      isLoadingSellersRetributionsRanking: false,
      sellersRetributionsRanking: [],
      sellerRetributionsModalOpen: false,
      rowSelected: null,
      sellerRetributions: [],
      detailsColumns: [
        { field: "index", label: "" },
        { field: "planSaleCustomId", label: "Numéro de contrat", slot: "planSaleCustomId" },
        { field: "purchasedAt", label: "Date de vente", slot: "purchasedAt" },
        { field: "paymentDate", label: "Date de versement", slot: "paymentDate" },
        { field: "amount", label: "Prime", price: true },
      ],
    };
  },

  async created() {
    await this.fetchSellerRetributionsPeriods();
  },

  computed: {
    ...mapGetters("navigation", ["isAdminPage"]),
    ...mapState({
      storeSelectedStoreId: (state) => state.store.selectedStoreId,
      retributionsSelectedStoreId: (state) => state.retributions.selectedStoreId,
    }),

    selectedStoreId() {
      return this.retributionsSelectedStoreId || this.storeSelectedStoreId;
    },

    periodOptions() {
      return this.periods.map(({ label, value }) => ({
        label:
          label === "current_month"
            ? this.$t("current_month")
            : label === "previous_month"
              ? this.$t("previous_month")
              : this.$d(new Date(value), "monthly"),
        value: value,
      }));
    },

    formattedSelectedPeriod() {
      if (!this.selectedPeriod) return "";
      return this.$d(this.selectedPeriod, "monthly");
    },

    columns() {
      return [
        { field: "rank", label: "" },
        { field: "sellerName", label: "Vendeur" },
        { field: "totalRetributions", label: "Contrats", numeric: true },
        { field: "totalAmount", label: "Prime", price: true },
      ];
    },

    rankingData() {
      let rank = 1;
      return [...this.sellersRetributionsRanking]
        .sort((a, b) => b.totalAmount - a.totalAmount)
        .map((sellersRetribution) => ({
          rank: rank++,
          sellerId: sellersRetribution.seller.id,
          sellerName: sellersRetribution.seller.name,
          totalRetributions: sellersRetribution.totalRetributions,
          totalAmount: sellersRetribution.totalAmount,
        }));
    },

    sellerRetributionsData() {
      let index = 1;
      return [...this.sellerRetributions].map((sellerRetribution) => ({
        index: index++,
        ...sellerRetribution,
      }));
    },
  },

  methods: {
    async updatePeriod(newPeriod) {
      this.selectedPeriod = newPeriod;
      this.fetchSellerRetributionsRanking();
    },

    async fetchSellerRetributionsPeriods() {
      this.isLoadingPeriods = true;
      const response = await StoreService.getSellerRetributionsPeriods(this.selectedStoreId, this.status);
      if (!response.success) return;

      this.isLoadingPeriods = false;
      this.periods = response.data;

      await this.updatePeriod(this.periods.length ? this.periods[this.periods.length - 1].value : null);
    },

    async fetchSellerRetributionsRanking() {
      if (!this.selectedPeriod) return;

      this.isLoadingSellersRetributionsRanking = true;
      const response = await StoreService.getSellerRetributionsRanking(
        this.selectedStoreId,
        this.selectedPeriod,
        this.status,
      );
      if (!response.success) return;

      this.isLoadingSellersRetributionsRanking = false;
      this.sellersRetributionsRanking = response.data.ranking;
      this.retributionDate = response.data.retributionDate;
    },

    async openSellerRetributionsModal(row) {
      this.rowSelected = row;
      this.sellerRetributionsModalOpen = true;
      const response = await StoreService.getSellerRetributions(
        this.selectedStoreId,
        this.selectedPeriod,
        row.sellerId,
        this.status,
      );
      if (!response.success) return;

      this.sellerRetributions = response.data;
    },
  },

  watch: {
    selectedStoreId: {
      handler: "fetchSellerRetributionsPeriods",
    },
    status: {
      handler: "fetchSellerRetributionsPeriods",
    },
  },
};
</script>
