<template>
  <div>
    <div class="px-2 my-4 flex justify-between items-center">
      <h1 class="text-4xl font-heading-1 tracking-tight text-gray-900">
        {{ $t("Retributions") }}
      </h1>

      <GenericButton @click="retributionsModalOpen = true">Grille de primes</GenericButton>
    </div>

    <TabsNavigation :tabs="tabs" class="mb-6" />

    <Modal size="big" :open="retributionsModalOpen" @close="retributionsModalOpen = false">
      <div class="flex justify-between items-center my-5">
        <h4 class="text-xl font-heading-1">Grille de primes</h4>
        <GenericButton @click="() => downloadRetributionsPdf(selectedStoreId)">Exporter</GenericButton>
      </div>

      <Loading v-if="isLoadingRetributions" />
      <RetributionTable v-else :retributions="retributions" hide-store-remuneration />
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Loading, Modal, TabsNavigation } from "@estaly/ui";
import GenericButton from "@/components/utils/GenericButton.vue";
import RetributionTable from "@/components/retribution/RetributionTable.vue";
import StoreService from "@/api/services/store.js";

export default {
  components: {
    TabsNavigation,
    GenericButton,
    Modal,
    RetributionTable,
    Loading,
  },

  data() {
    return {
      isLoadingRetributions: true,
      retributionsModalOpen: false,
      retributions: [],
      tabs: [
        {
          name: "pending",
          path: "/pos/retributions/pending",
          displayName: "Primes en attente",
        },
        {
          name: "paid",
          path: "/pos/retributions/paid",
          displayName: "Primes versées",
        },
      ],
    };
  },

  computed: {
    ...mapState("store", ["selectedStoreId"]),
  },

  methods: {
    ...mapActions("retributions", ["downloadRetributionsPdf"]),
    async fetchRetributions() {
      const response = await StoreService.getRetributions(this.selectedStoreId);
      if (!response.success) return;

      this.retributions = response.data;
      this.isLoadingRetributions = false;
    },
  },

  watch: {
    async retributionsModalOpen(open) {
      if (open && !this.retributions.length) {
        await this.fetchRetributions();
      }
    },
  },
};
</script>
