<template>
  <div class="mb-2 bg-white p-4 rounded-md">
    <p class="text-xl font-heading-1 pb-2 tracking-tight text-gray-900">
      {{ $t("Seller informations") }}
    </p>
    <div>
      <div>
        <OfflineSellerCombobox :sellerCompleteName="localSellerCompleteName" @selectedSeller="handleSelectSeller" />
        <div class="mt-1">
          <Field
            id="sellerCompleteName"
            name="sellerCompleteName"
            type="text"
            class="hidden"
            v-model="localSellerCompleteName"
            :key="localSellerCompleteName"
          />
          <ErrorMessage name="sellerCompleteName" class="mt-2 text-sm text-red-600" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";
import OfflineSellerCombobox from "@/components/combobox/OfflineSellerCombobox.vue";

export default {
  components: {
    Field,
    ErrorMessage,
    OfflineSellerCombobox,
  },
  props: {
    sellerCompleteName: String,
  },
  inject: ["validationContext"],
  data() {
    return {
      localSellerCompleteName: this.sellerCompleteName || "",
    };
  },
  watch: {
    sellerCompleteName(newValue) {
      this.localSellerCompleteName = newValue || "";
    },
  },
  mounted() {
    this.validationContext?.validateField("sellerCompleteName");
  },
  methods: {
    handleSelectSeller(seller) {
      const selectedName = seller ? seller.name : "";
      this.$emit("selectedSeller", seller);

      this.$nextTick(() => {
        this.localSellerCompleteName = selectedName;
        this.validationContext?.setFieldValue("sellerCompleteName", selectedName);
        this.validationContext?.setTouched("sellerCompleteName", true);
        this.validationContext?.validateField("sellerCompleteName");
      });
    },
  },
};
</script>
