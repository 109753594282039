import { plainAxiosInstance, securedAxiosInstance } from "../axios";
import { toSnakeCase } from "@estaly/ui/src/utils/data_formatter";

class LeadService {
  list(params) {
    return securedAxiosInstance.get("/leads", { params: toSnakeCase(params) });
  }

  create(storeId, params) {
    return securedAxiosInstance.post(`/api/v1/store/${storeId}/leads`, toSnakeCase(params));
  }

  update(uniqueToken, params) {
    return plainAxiosInstance.put(`/public/leads/${uniqueToken}`, toSnakeCase(params));
  }

  createPaymentIntent(storeId, params) {
    return securedAxiosInstance.post(`/store/${storeId}/leads/payment_intents`, params);
  }

  sendConsentCode(storeId, leadId) {
    return securedAxiosInstance.post(`/store/${storeId}/leads/consent_codes?lead_id=${leadId}`);
  }

  verifyConsentCode(storeId, leadId, code) {
    return securedAxiosInstance.put(
      `/store/${storeId}/leads/consent_codes/verification?lead_id=${leadId}&consent_code=${code}`,
    );
  }

  reportMissingCode(storeId, leadId) {
    return securedAxiosInstance.post(`/store/${storeId}/leads/consent_codes/missing_code?lead_id=${leadId}`);
  }
}

export default new LeadService();
