<template>
  <div class="bg-white shadow sm:rounded-t-md py-6 px-4 sm:px-6 lg:px-8 flex justify-between">
    <img class="h-12 w-auto" src="@/assets/large_logo_estaly_black.png" alt="Estaly logo" />
    <div class="flex gap-x-6 items-center">
      <button class="ml-4 text-xs cursor-pointer hover:underline" @click="redirectToStore">Se connecter</button>
      <div><GenericButton @onClick="redirectToJoinEstalyPage">Rejoindre l'aventure</GenericButton></div>
    </div>
  </div>

  <div class="mt-6 px-4 sm:px-6 lg:px-8">
    <TabsNavigation :tabs="tabs" class="mb-6" />
    <div class="mt-8">
      <component :is="currentTabComponent" />
    </div>
  </div>
</template>

<script>
import InvoicesAnalytics from "@/components/organization/InvoicesAnalytics.vue";
import CommissionsAnalytics from "@/components/organization/CommissionsAnalytics.vue";
import ClaimsAnalytics from "@/components/organization/ClaimsAnalytics.vue";
import GenericButton from "@/components/utils/GenericButton.vue";
import { TabsNavigation } from "@estaly/ui";

export default {
  components: {
    InvoicesAnalytics,
    CommissionsAnalytics,
    ClaimsAnalytics,
    GenericButton,
    TabsNavigation,
  },

  computed: {
    currentTabComponent() {
      const componentsMap = {
        commissions: "CommissionsAnalytics",
        invoices: "InvoicesAnalytics",
        claims: "ClaimsAnalytics",
      };

      return componentsMap[this.activeTab] || "CommissionsAnalytics";
    },
  },

  data() {
    return {
      tabs: [
        { name: "commissions", displayName: "Suivi des ventes", query: { tab: "commissions" } },
        { name: "invoices", displayName: "Rémunération réelle", query: { tab: "invoices" } },
        { name: "claims", displayName: "Sinistres", query: { tab: "claims" } },
      ],
      activeTab: "commissions",
    };
  },

  methods: {
    redirectToStore() {
      window.open("https://store.estaly.co", "_blank");
    },

    redirectToJoinEstalyPage() {
      window.open("https://airtable.com/app7bZfSpbgoO64ZG/pagHyEEP5IaW9FwgC/form", "_blank");
    },
  },

  watch: {
    "$route.query.tab": {
      immediate: true,
      handler(newTab) {
        this.activeTab = newTab || "commissions";
      },
    },
  },
};
</script>
