<template>
  <Alert class="mt-8">
    <template #title>Calcul de la rémunération</template>
    <p>
      Le tableau “Rémunération” indique le montant de rémunération estimé sur la base des ventes réalisées pendant la
      période de vente sélectionnée. Cette rémunération sera effectivement versée à M+2. Par exemple, pour des ventes
      réalisées en mars 2025, le versement correspondant sera effectué en mai 2025.
    </p>
  </Alert>

  <div
    class="mt-8 bg-white shadow sm:rounded-t-md py-6 px-4 lg:px-6 gap-x-6 flex flex-col sm:flex-row justify-between mx-auto"
  >
    <div class="pt-2 sm:w-64">
      <p class="block text-sm leading-6 text-gray-900 sm:pt-1.5 font-semibold">Période de vente</p>
      <AnalyticsDateRangePicker
        class="text-sm w-full rounded-md"
        :placeholder="$t('Select a time range')"
        :model-value="dateRange"
        @update:modelValue="dateRangeUpdated"
      />
    </div>
    <div class="sm:w-64">
      <MultiSelect
        id="store-selector"
        label="Magasins"
        :options="storeOptions"
        v-model="selectedStores"
        placeholder="Choisissez un ou plusieurs magasins"
      />
    </div>
  </div>

  <div class="mt-8 grid grid-cols-1 gap-6 md:grid-cols-3">
    <div class="bg-white shadow rounded-md py-4 px-4 lg:px-6">
      <h2 class="text-lg font-semibold">Rémunération estimée</h2>
      <span v-if="isPresent(selectedCommissions)" class="text-md font-semibold text-green-500">
        {{ formatPrice(selectedTotalCommission) }}
      </span>
      <span v-else class="text-md font-semibold text-green-500">
        {{ formatPrice(totalCommission) }}
      </span>
      <span class="text-sm" v-if="isPresent(selectedCommissions)">
        (Compa. Réseau:
        <span :class="comparisonClass(totalAverageCommissions, commissionsAverage)">
          {{ comparisonPercentage(totalAverageCommissions, commissionsAverage) }}%
        </span>
        )
      </span>
      <div v-if="!analytics?.commissions?.length" class="text-gray-500 text-center py-4">
        Aucune donnée disponible pour la période sélectionnée. Veuillez sélectionner une autre période.
      </div>
      <Table v-else :columns="commissionColumns" :data="formattedCommissions" :isRowClickable="false"></Table>
    </div>

    <div class="bg-white shadow rounded-md py-4 px-4 lg:px-6">
      <h2 class="text-lg font-semibold">Rémunération moyenne par contrat</h2>
      <span v-if="isPresent(selectedCommissionsByContract)" class="text-md font-semibold text-green-500">
        {{ formatPrice(selectedAverageCommissionsByContract) }}
      </span>
      <span v-else class="text-md font-semibold text-green-500">
        {{ formatPrice(commissionsByContractAverage) }}
      </span>
      <span class="text-sm" v-if="isPresent(selectedCommissionsByContract)">
        (Compa. Réseau:
        <span :class="comparisonClass(selectedAverageCommissionsByContract, commissionsByContractAverage)">
          {{ comparisonPercentage(selectedAverageCommissionsByContract, commissionsByContractAverage) }}%
        </span>
        )
      </span>
      <div v-if="!analytics?.commissions?.length" class="text-gray-500 text-center py-4">
        Aucune donnée disponible pour la période sélectionnée. Veuillez sélectionner une autre période.
      </div>
      <Table
        v-else
        :columns="commissionByContractColumns"
        :data="formattedCommissionsByContract"
        :isRowClickable="false"
      ></Table>
    </div>

    <div class="bg-white shadow rounded-md py-4 px-4 lg:px-6">
      <h2 class="text-lg font-semibold">Ventes réalisées</h2>
      <span v-if="isPresent(selectedSales)" class="text-md font-semibold text-green-500">
        {{ selectedTotalSales }}
      </span>
      <span v-else class="text-md font-semibold text-green-500">
        {{ totalSales }}
      </span>
      <span class="text-sm" v-if="isPresent(selectedSales)">
        (Compa. Réseau:
        <span :class="comparisonClass(totalAverageSales, salesAverage)">
          {{ comparisonPercentage(totalAverageSales, salesAverage) }}%
        </span>
        )
      </span>
      <div v-if="!analytics?.commissions?.length" class="text-gray-500 text-center py-4">
        Aucune donnée disponible pour la période sélectionnée. Veuillez sélectionner une autre période.
      </div>
      <Table v-else :columns="salesColumns" :data="formattedSales" :isRowClickable="false"></Table>
    </div>
  </div>
</template>

<script>
import MultiSelect from "@/components/utils/MultiSelect.vue";
import { Alert } from "@estaly/ui";
import AnalyticsService from "@/api/services/analytics.js";
import { isPresent } from "@/utils/validation";
import { AnalyticsDateRangePicker } from "@estaly/ui";
import { formatPrice } from "@estaly/ui/src/utils/price_formatter";
import { mapActions } from "vuex";
import { toCamelCase } from "@estaly/ui/src/utils/data_formatter";
import { currentMonthRange, inputDateFormat } from "@estaly/ui/src/utils/date";
import { Table } from "@estaly/ui";

export default {
  components: {
    Alert,
    AnalyticsDateRangePicker,
    MultiSelect,
    Table,
  },
  data() {
    return {
      dateRange: currentMonthRange().map((date) => inputDateFormat(date)),
      analytics: null,
      selectedStores: [],
      commissionsAverage: null,
      salesAverage: null,
      commissionsByContractAverage: null,
      token: null,
    };
  },

  mounted() {
    const token = this.$route.query.token;
    if (!token) {
      this.notify({
        category: "simple",
        type: "error",
        title: "Un token est requis pour accéder à cette page.",
      });
      return;
    }

    this.token = token;
    this.withLoader(this.fetchAnalytics);
  },

  computed: {
    commissionColumns() {
      return [
        { label: "Position", field: "position" },
        { label: "Magasin", field: "entityName" },
        { label: "M", field: "amount" },
      ];
    },

    formattedCommissions() {
      return (
        this.analytics?.commissions?.map((item, index) => ({
          position: index + 1,
          entityName: item.entityName,
          amount: this.formatPrice(item.amount),
          rowClass: this.selectedStores.includes(item.entityName) ? "bg-green-200" : "",
        })) || []
      );
    },

    salesColumns() {
      return [
        { label: "Position", field: "position" },
        { label: "Magasin", field: "entityName" },
        { label: "M", field: "sales" },
      ];
    },

    formattedSales() {
      return (
        this.analytics?.sales?.map((item, index) => ({
          position: index + 1,
          entityName: item.entityName,
          sales: item.sales,
          rowClass: this.selectedStores.includes(item.entityName) ? "bg-green-200" : "",
        })) || []
      );
    },

    commissionByContractColumns() {
      return [
        { label: "Position", field: "position" },
        { label: "Magasin", field: "entityName" },
        { label: "M", field: "amount" },
      ];
    },

    formattedCommissionsByContract() {
      return (
        this.analytics?.commissionsByContract?.map((item, index) => ({
          position: index + 1,
          entityName: item.entityName,
          amount: this.formatPrice(item.amount),
          rowClass: this.selectedStores.includes(item.entityName) ? "bg-green-200" : "",
        })) || []
      );
    },

    params() {
      return {
        startDate: this.dateRange[0],
        endDate: this.dateRange[1],
        token: this.token,
      };
    },
    storeOptions() {
      return this.analytics?.commissions
        .map((item) => ({
          label: item.entityName,
          value: item.entityName,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    },
    selectedCommissions() {
      return this.analytics?.commissions.filter((item) => this.selectedStores.includes(item.entityName));
    },
    selectedSales() {
      return this.analytics?.sales.filter((item) => this.selectedStores.includes(item.entityName));
    },
    selectedCommissionsByContract() {
      return this.analytics?.commissionsByContract.filter((item) => this.selectedStores.includes(item.entityName));
    },
    selectedTotalCommission() {
      return this.selectedCommissions.reduce((sum, item) => sum + Number(item.amount), 0);
    },
    selectedTotalSales() {
      return this.selectedSales.reduce((sum, item) => sum + item.sales, 0);
    },
    selectedAverageCommissionsByContract() {
      return (
        this.selectedCommissionsByContract.reduce((sum, item) => sum + Number(item.amount), 0) /
        this.selectedCommissionsByContract.length
      );
    },
    totalAverageCommissions() {
      return (
        this.selectedCommissions.reduce((sum, item) => sum + Number(item.amount), 0) / this.selectedCommissions.length
      );
    },
    totalAverageSales() {
      return this.selectedSales.reduce((sum, item) => sum + item.sales, 0) / this.selectedSales.length;
    },
    totalCommission() {
      return this.analytics?.commissions.reduce((sum, item) => sum + Number(item.amount), 0);
    },
    totalSales() {
      return this.analytics?.sales.reduce((sum, item) => sum + item.sales, 0);
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    ...mapActions("store", ["withLoader"]),
    formatPrice,
    isPresent,

    comparisonPercentage(value, average) {
      if (!value || !average) return 0;
      const percentage = ((value - average) / average) * 100;
      return percentage.toFixed(2);
    },

    comparisonClass(value, average) {
      if (!value || !average) return "text-gray-500";
      return value > average ? "text-green-500" : "text-red-500";
    },

    async fetchAnalytics() {
      let response = await AnalyticsService.computeOrganizationCommissionsAnalytics(this.params);
      if (response?.success) {
        const data = toCamelCase(response.data);

        this.analytics = data.analytics;
        this.commissionsAverage = this.analytics.commissionsAverage;
        this.salesAverage = this.analytics.salesAverage;
        this.commissionsByContractAverage = this.analytics.commissionsByContractAverage;
      }
    },

    dateRangeUpdated(dateRange) {
      if (!dateRange || dateRange.includes(null)) {
        this.dateRange = [];
      } else {
        this.dateRange = dateRange;
      }
      this.withLoader(this.fetchAnalytics);
    },
  },
};
</script>
