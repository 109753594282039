<template>
  <Modal :open="open" @close="close">
    <VeeForm v-slot="{ handleSubmit }" :validation-schema="customerDetailsSchema" as="div" ref="leadCreationForm">
      <form @submit="handleSubmit($event, submitLeadUpdate)">
        <div class="mt-2 bg-white p-4 rounded-md">
          <p class="text-xl font-heading-1 pb-2 tracking-tight text-gray-900">
            {{ $t("Client informations") }}
          </p>
          <div class="grid grid-cols-2 gap-x-4 gap-y-3">
            <div class="flex-1">
              <label for="birthDate" class="block text-sm text-gray-700">
                {{ $t("Birth date") }}
                <span class="ml-1 text-red-600">*</span>
              </label>
              <Field
                type="date"
                name="customer.birthDate"
                id="birthDate"
                autocomplete="birth-date"
                class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="01/01/1970"
                @keydown="blockEnterBehavior"
                v-model="customer.birthDate"
              />
              <ErrorMessage name="customer.birthDate" class="mt-2 text-sm text-red-600" />
            </div>
            <div class="flex-1">
              <label for="birthCity" class="block text-sm text-gray-700">
                {{ $t("Birth city") }}
                <span class="ml-1 text-red-600">*</span>
              </label>
              <Field
                type="text"
                name="customer.birthCity"
                id="birthCity"
                class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="Paris"
                @keydown="blockEnterBehavior"
                v-model="customer.birthCity"
              />
              <ErrorMessage name="customer.birthCity" class="mt-2 text-sm text-red-600" />
            </div>
            <div class="flex-1">
              <label for="birthCountry" class="block text-sm text-gray-700">
                {{ $t("Birth country") }}
                <span class="ml-1 text-red-600">*</span>
              </label>
              <Field
                type="text"
                name="customer.birthCountry"
                id="birthCountry"
                class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="France"
                @keydown="blockEnterBehavior"
                v-model="customer.birthCountry"
              />
              <ErrorMessage name="customer.birthCountry" class="mt-2 text-sm text-red-600" />
            </div>
            <GoogleAddress class="flex-1" @newAddress="newAddress" :addressError="address.addressError" />
          </div>
        </div>
        <div>
          <button
            type="submit"
            class="mt-2 w-full justify-center py-2 px-4 border border-transparent shadow-sm text-lg font-medium text-button-font-primary bg-primary transition duration-300 transform hover:scale-105 rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            <div class="flex align-center justify-center">
              <p class="uppercase font-body">Suivant</p>
            </div>
          </button>
        </div>
      </form>
    </VeeForm>
  </Modal>
</template>

<script>
import { Modal } from "@estaly/ui";
import { ErrorMessage, Field, Form as VeeForm } from "vee-validate";
import { customerDetailsSchema } from "@/components/pos/subscription/utils/validation";
import GoogleAddress from "@/components/address/GoogleAddress";
import { isPresent } from "@/utils/validation";
import LeadService from "@/api/services/lead";

export default {
  components: {
    Modal,
    VeeForm,
    Field,
    ErrorMessage,
    GoogleAddress,
  },

  props: ["open", "estalyId"],

  data() {
    return {
      customerDetailsSchema,
      customer: {
        birthDate: "",
        birthCity: "",
        birthCountry: "",
      },
      address: {
        address1: "",
        city: "",
        province: "",
        country: "",
        zipCode: "",
        addressError: false,
      },
    };
  },

  computed: {},

  methods: {
    blockEnterBehavior(event) {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
      }
    },

    close: function () {
      this.$emit("close");
    },

    newAddress(value) {
      this.address = value;
      if (isPresent(this.address.address1) && isPresent(this.address.city) && isPresent(this.address.country)) {
        this.address.addressError = false;
      }
    },

    async submitLeadUpdate() {
      if (isPresent(this.address.address1) && isPresent(this.address.city) && isPresent(this.address.country)) {
        const params = {
          lead: {
            customer: this.customer,
            address: this.address,
          },
        };
        const response = await LeadService.update(this.estalyId, params);
        if (response?.success) {
          this.$emit("showOfferPayment");
        }
      } else {
        this.address.addressError = true;
      }
    },
  },
};
</script>
