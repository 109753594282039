<template>
  <div>
    <div v-if="isPresent(activeChallenge)" class="fixed top-0 w-full z-30">
      <ChallengeBanner />
    </div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <HeadlessUIDialog as="div" class="fixed inset-0 flex z-50 md:hidden" @close="sidebarOpen = false">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="flex-shrink-0 flex items-center justify-between px-4">
              <img class="h-8 w-auto" src="@/assets/large_logo_estaly_black.png" alt="Estaly" />
            </div>
            <div class="mt-5 flex-1 h-0 overflow-y-auto">
              <div v-if="this.currentUserHasPermission('admin')">
                <AdminSelector class="px-2 ml-1 pb-4" />
              </div>
              <nav class="px-2 space-y-1">
                <router-link
                  v-for="item in navigationItems"
                  v-bind:key="item.name"
                  v-bind:to="item.to"
                  :class="[
                    this.isCurrentActivePage(item.name) ? 'text-primary-dark' : '',
                    'group flex items-center px-2 py-1.5 text-sm rounded-md text-gray-500',
                  ]"
                >
                  <component
                    v-bind:is="item.icon"
                    class="mr-4 flex-shrink-0 h-4 w-4 text-gray-500"
                    :class="[this.isCurrentActivePage(item.name) ? 'text-primary-dark' : '']"
                    aria-hidden="true"
                  />
                  {{ $t("Navigation." + item.name) }}
                </router-link>
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </HeadlessUIDialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div
      class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0"
      :class="{ 'mt-8': isPresent(activeChallenge) }"
    >
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex flex-col flex-grow pt-5 bg-white overflow-y-auto">
        <div class="flex items-center justify-between flex-shrink-0 px-4">
          <img class="h-10 w-auto" src="@/assets/large_logo_estaly_black.png" alt="Estaly" />
          <HeadlessUIMenu as="div" class="ml-3 relative">
            <div>
              <MenuButton class="max-w-xs bg-white flex items-center text-sm rounded-full">
                <span class="sr-only">Open user menu</span>
                <div
                  class="h-10 w-10 rounded-full bg-primary-lighter hover:bg-primary flex items-center justify-center"
                >
                  <p class="text-white">{{ currentUser.initials }}</p>
                </div>
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="z-50 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <MenuItem v-slot="{ active }">
                  <router-link
                    to="/pos/profile"
                    :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                  >
                    {{ $t("Profile") }}
                  </router-link>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <span
                    @click="signout"
                    :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                  >
                    {{ $t("Sign out") }}
                  </span>
                </MenuItem>
              </MenuItems>
            </transition>
          </HeadlessUIMenu>
        </div>
        <div class="mt-5 flex-1 flex flex-col">
          <div v-if="this.currentUserHasPermission('admin')">
            <AdminSelector class="px-2 ml-1 pb-4" />
          </div>
          <nav class="flex-1 px-2 pb-4 space-y-1">
            <router-link
              v-for="item in navigationItems"
              :key="item.name"
              :to="item.to"
              :class="[
                this.isCurrentActivePage(item.name) ? 'text-primary-dark' : '',
                'group flex items-center px-2 py-1.5 text-sm hover:bg-gray-200 rounded-md text-gray-500',
              ]"
            >
              <component
                :is="item.icon"
                class="mr-3 flex-shrink-0 h-4 w-4 text-gray-500"
                :class="[this.isCurrentActivePage(item.name) ? 'text-primary-dark' : '']"
                aria-hidden="true"
              />
              {{ $t("Navigation." + item.name) }}
            </router-link>
          </nav>
        </div>
      </div>
    </div>
    <div class="md:pl-64 flex flex-col flex-1" :class="{ 'mt-8': isPresent(activeChallenge) }">
      <div
        class="sm:hidden sticky z-20 flex-shrink-0 flex h-16 bg-white shadow"
        :class="isPresent(activeChallenge) ? 'top-8' : 'top-0'"
      >
        <button
          type="button"
          class="px-4border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary md:hidden"
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
        </button>
        <div class="flex-1 px-4 flex justify-between">
          <div class="flex-1 flex"></div>
          <div class="ml-4 flex items-center md:ml-6">
            <div class="p-2">
              <a
                class="flex items-center justify-center cursor-pointer"
                target="_blank"
                href="https://estaly.notion.site/FAQ-54e8759929b34ae6a87d05f93abc631e"
              >
                <QuestionMarkCircleIcon class="h-5 w-5 text-gray-500" />
                <p class="ml-1 text-sm text-gray-500">Help</p>
              </a>
            </div>
            <HeadlessUIMenu as="div" class="ml-3 relative">
              <div>
                <MenuButton class="max-w-xs bg-white flex items-center text-sm rounded-full">
                  <span class="sr-only">Open user menu</span>
                  <div
                    class="h-10 w-10 rounded-full bg-primary-lighter hover:bg-primary flex items-center justify-center"
                  >
                    <p class="text-white">{{ currentUser.initials }}</p>
                  </div>
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="z-50 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <MenuItem v-slot="{ active }">
                    <router-link
                      to="/pos/profile"
                      :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                    >
                      {{ $t("Profile") }}
                    </router-link>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <span
                      @click="signout"
                      :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                    >
                      {{ $t("Sign out") }}
                    </span>
                  </MenuItem>
                </MenuItems>
              </transition>
            </HeadlessUIMenu>
          </div>
        </div>
      </div>

      <div class="relative min-h-screen">
        <LayoutLoading />
        <main>
          <div class="py-4 pb-24">
            <div class="px-4">
              <router-view></router-view>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Dialog as HeadlessUIDialog,
  DialogOverlay,
  Menu as HeadlessUIMenu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import {
  BellIcon,
  MenuAlt2Icon,
  XIcon,
  CloudUploadIcon,
  QuestionMarkCircleIcon,
  ShoppingCartIcon,
  ViewListIcon,
  DesktopComputerIcon,
  UserCircleIcon,
  FireIcon,
  PresentationChartLineIcon,
  ArchiveIcon,
  SparklesIcon,
  CollectionIcon,
} from "@heroicons/vue/outline";

import { SearchIcon } from "@heroicons/vue/solid";
import { mapActions, mapState, mapGetters } from "vuex";
import AdminSelector from "../components/selector/AdminSelector.vue";
import { isPresent } from "@/utils/validation";
import { initIntercom } from "@/utils/intercom.js";
import { toCamelCase } from "@estaly/ui/src/utils/data_formatter";
import StoreService from "@/api/services/store";

import LayoutLoading from "@/components/utils/LayoutLoading.vue";
import ChallengeBanner from "@/components/challenge/ChallengeBanner.vue";

const navigation = [
  {
    name: "Subscription",
    to: "/pos",
    icon: DesktopComputerIcon,
  },
  {
    name: "Leads",
    to: "/leads",
    icon: CollectionIcon,
  },
  {
    name: "Performances",
    to: "/pos/analytics",
    icon: PresentationChartLineIcon,
  },
  {
    name: "Retributions",
    to: "/pos/retributions/pending",
    icon: SparklesIcon,
  },
  {
    name: "Challenge",
    to: "/pos/challenge",
    icon: FireIcon,
  },
  {
    name: "Plan sales",
    to: "/pos/plan-sales",
    icon: ArchiveIcon,
  },
  {
    name: "Claim List",
    to: "/pos/claims",
    icon: ViewListIcon,
  },
  {
    name: "Sellers",
    to: "/pos/sellers",
    icon: UserCircleIcon,
  },
];

export default {
  components: {
    HeadlessUIDialog,
    DialogOverlay,
    HeadlessUIMenu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    MenuAlt2Icon,
    SearchIcon,
    XIcon,
    CloudUploadIcon,
    LayoutLoading,
    QuestionMarkCircleIcon,
    ShoppingCartIcon,
    AdminSelector,
    DesktopComputerIcon,
    ArchiveIcon,
    ChallengeBanner,
    CollectionIcon,
  },

  data: () => {
    return {
      navigation,
      sidebarOpen: false,
      activeChallenge: {},
    };
  },

  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        this.updateNavigation();
        if (to?.path != from?.path) this.refreshCurrentUser();
      },
    },
  },

  beforeMount() {
    this.updateNavigation();
  },

  mounted() {
    document.title = "Estaly In Store";
    initIntercom(this.currentUser.email, this.currentUser.intercomUserHash, this.currentUser.firstName);
    this.retrieveChallengePerformances();
  },

  computed: {
    ...mapState("store", ["selectedStoreId"]),
    ...mapState("auth", ["currentUser"]),
    ...mapGetters("auth", ["currentUserHasPermission"]),
    ...mapGetters("navigation", ["isCurrentActivePage"]),
    ...mapGetters("store", ["selectedStore"]),

    navigationItems() {
      return this.navigation.filter((item) => {
        if (item.name === "Retributions") return this.currentUserHasPermission("retributions");
        return true;
      });
    },
  },

  methods: {
    ...mapActions("auth", ["signout", "refreshCurrentUser"]),
    ...mapActions("navigation", ["updateActivePage", "resetActivePage"]),
    isPresent,

    updateNavigation() {
      const activePage = isPresent(this.$route.meta)
        ? navigation.find((item) => item["name"] === this.$route.meta.navigation)
        : null;

      if (isPresent(activePage)) {
        this.updateActivePage(activePage);
      } else {
        this.resetActivePage();
      }
    },

    async retrieveChallengePerformances() {
      const response = await StoreService.getChallenges(this.selectedStoreId);
      if (!response?.success) return;

      const data = toCamelCase(response.data);
      this.activeChallenge = data.challenges.find((challenge) => challenge.active);
    },
  },
};
</script>
