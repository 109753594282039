import { phoneRegex } from "@estaly/ui/src/utils/validation";
import * as yup from "yup";

const today = new Date();
const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

export const saleFormSchema = yup.object({
  lead: yup.object({
    invitationSentByEstaly: yup.boolean(),
  }),
  customer: yup.object({
    email: yup.string().required("L'email est requis.").email("L'email doit être valide"),
    phone: yup
      .string()
      .required("Le numéro de téléphone est requis.")
      .matches(phoneRegex, "Le numéro de téléphone n'est pas valide pour la France ou les départements d'outre-mer."),
    firstName: yup
      .string()
      .required("Le prénom du client est requis.")
      .min(2, "Le prénom doit comporter au moins 2 caractères."),
    lastName: yup
      .string()
      .required("Le nom du client est requis.")
      .min(2, "Le nom doit comporter au moins 2 caractères."),
    birthDate: yup
      .date()
      .required("La date de naissance est requise.")
      .typeError("La date de naissance est invalide.")
      .max(eighteenYearsAgo, "L'utilisateur doit avoir 18 ans ou plus."),
    birthCity: yup
      .string()
      .required("La ville de naissance est requise.")
      .min(2, "La ville de naissance doit comporter au moins 2 caractères."),
    birthCountry: yup
      .string()
      .required("Le pays de naissance est requis.")
      .min(2, "Le pays de naissance doit comporter au moins 2 caractères."),
  }),
  product: yup.object({
    title: yup.string().required("Le nom du produit est requis."),
    price: yup
      .string()
      .required("Le prix du produit est requis.")
      .matches(/^\d+(\.\d{1,2})?$|^\d+(,\d{1,2})?$/, "Le format du prix est invalide."),
    referenceId: yup
      .string()
      .required("Un identifiant du produit unique est requis.")
      .min(2, "L'identifiant du produit doit comporter au moins 2 caractères."),
  }),
  sellerCompleteName: yup.string().required("Veuillez sélectionner un vendeur"),
});

export const leadFormSchema = yup.object({
  lead: yup.object({
    invitationSentByEstaly: yup.boolean(),
  }),
  customer: yup.object({
    email: yup.string().required("L'email est requis.").email("L'email doit être valide"),
    phone: yup
      .string()
      .required("Le numéro de téléphone est requis.")
      .matches(phoneRegex, "Le numéro de téléphone n'est pas valide pour la France ou les départements d'outre-mer."),
    firstName: yup
      .string()
      .required("Le prénom du client est requis.")
      .min(2, "Le prénom doit comporter au moins 2 caractères."),
    lastName: yup
      .string()
      .required("Le nom du client est requis.")
      .min(2, "Le nom doit comporter au moins 2 caractères."),
  }),
  product: yup.object({
    title: yup.string().required("Le nom du produit est requis."),
    price: yup
      .string()
      .required("Le prix du produit est requis.")
      .matches(/^\d+(\.\d{1,2})?$|^\d+(,\d{1,2})?$/, "Le format du prix est invalide."),
    referenceId: yup
      .string()
      .required("Un identifiant du produit unique est requis.")
      .min(2, "L'identifiant du produit doit comporter au moins 2 caractères."),
  }),
  sellerCompleteName: yup.string().required("Veuillez sélectionner un vendeur"),
});

export const customerDetailsSchema = yup.object({
  customer: yup.object({
    birthDate: yup
      .date()
      .required("La date de naissance est requise.")
      .typeError("La date de naissance est invalide.")
      .max(eighteenYearsAgo, "L'utilisateur doit avoir 18 ans ou plus."),
    birthCity: yup
      .string()
      .required("La ville de naissance est requise.")
      .min(2, "La ville de naissance doit comporter au moins 2 caractères."),
    birthCountry: yup
      .string()
      .required("Le pays de naissance est requis.")
      .min(2, "Le pays de naissance doit comporter au moins 2 caractères."),
  }),
});
