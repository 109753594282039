<template>
  <div class="mt-2 bg-white p-4 rounded-md">
    <p class="text-xl font-heading-1 pb-2 tracking-tight text-gray-900">
      {{ $t("Client informations") }}
    </p>
    <div class="grid grid-cols-2 gap-x-4 gap-y-3">
      <div class="flex-1">
        <label for="firstName" class="block text-sm text-gray-700">
          {{ $t("First Name") }}
          <span class="ml-1 text-red-600">*</span>
        </label>
        <Field
          type="text"
          name="customer.firstName"
          id="firstName"
          class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
          placeholder="John"
          v-model="localCustomer.firstName"
          @keydown="blockEnterBehavior"
        />
        <ErrorMessage name="customer.firstName" class="mt-2 text-sm text-red-600" />
      </div>

      <div class="flex-1">
        <label for="lastName" class="block text-sm text-gray-700">
          {{ $t("Last Name") }}
          <span class="ml-1 text-red-600">*</span>
        </label>
        <Field
          type="text"
          name="customer.lastName"
          id="lastName"
          class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
          placeholder="Doe"
          v-model="localCustomer.lastName"
          @keydown="blockEnterBehavior"
        />
        <ErrorMessage name="customer.lastName" class="mt-2 text-sm text-red-600" />
      </div>

      <div class="flex-1 col-span-2">
        <label for="email" class="block text-sm text-gray-700">
          {{ $t("Customer email") }}
          <span class="ml-1 text-red-600">*</span>
        </label>
        <Field
          id="email"
          name="customer.email"
          type="email"
          class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
          placeholder="customer@gmail.com"
          v-model="localCustomer.email"
          @keydown="blockEnterBehavior"
        />
        <ErrorMessage name="customer.email" class="mt-2 text-sm text-red-600" />
      </div>

      <div class="flex-1">
        <label for="phone" class="block text-sm text-gray-700">
          {{ $t("Customer phone number") }}
          <span class="ml-1 text-red-600">*</span>
        </label>
        <Field
          id="phone"
          name="customer.phone"
          type="tel"
          class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
          placeholder="+33 6 99 49 75 90"
          v-model="localCustomer.phone"
          @keydown="blockEnterBehavior"
        />
        <ErrorMessage name="customer.phone" class="mt-2 text-sm text-red-600" />
      </div>

      <div class="flex-1">
        <label for="birthDate" class="block text-sm text-gray-700">
          {{ $t("Birth date") }}
          <span class="ml-1 text-red-600">*</span>
        </label>
        <Field
          type="date"
          name="customer.birthDate"
          id="birthDate"
          class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
          placeholder="01/01/1970"
          v-model="localCustomer.birthDate"
          @keydown="blockEnterBehavior"
        />
        <ErrorMessage name="customer.birthDate" class="mt-2 text-sm text-red-600" />
      </div>

      <div class="flex-1">
        <label for="birthCity" class="block text-sm text-gray-700">
          {{ $t("Birth city") }}
          <span class="ml-1 text-red-600">*</span>
        </label>
        <Field
          type="text"
          name="customer.birthCity"
          id="birthCity"
          class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
          placeholder="Paris"
          v-model="localCustomer.birthCity"
          @keydown="blockEnterBehavior"
        />
        <ErrorMessage name="customer.birthCity" class="mt-2 text-sm text-red-600" />
      </div>

      <div class="flex-1">
        <label for="birthCountry" class="block text-sm text-gray-700">
          {{ $t("Birth country") }}
          <span class="ml-1 text-red-600">*</span>
        </label>
        <Field
          type="text"
          name="customer.birthCountry"
          id="birthCountry"
          class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
          placeholder="France"
          v-model="localCustomer.birthCountry"
          @keydown="blockEnterBehavior"
        />
        <ErrorMessage name="customer.birthCountry" class="mt-2 text-sm text-red-600" />
      </div>

      <GoogleAddress class="flex-1" @newAddress="updateAddress" :addressError="addressError" />

      <div class="flex-1">
        <label for="address2" class="block text-sm text-gray-700">{{ $t("Additional address") }}</label>
        <Field
          type="text"
          name="address.address2"
          id="address2"
          class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
          placeholder="Résidence, Etage..."
          v-model="localAddress2"
          @input="$emit('update:address2', localAddress2)"
          @keydown="blockEnterBehavior"
        />
        <ErrorMessage name="address.address2" class="mt-2 text-sm text-red-600" />
      </div>
    </div>
  </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";
import GoogleAddress from "@/components/address/GoogleAddress";

export default {
  components: {
    Field,
    ErrorMessage,
    GoogleAddress,
  },
  props: {
    modelValue: Object,
    address2: String,
    addressError: Boolean,
  },
  inject: ["validationContext"],
  data() {
    return {
      localAddress2: this.address2 || "",
    };
  },
  computed: {
    localCustomer: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
      watch: {
        address2: {
          immediate: true,
          handler(newVal) {
            this.localAddress2 = newVal;
          },
        },
        localAddress2(newVal) {
          this.$emit("update:address2", newVal);
        },
      },
    },
  },
  mounted() {
    this.validationContext?.validateField("customer.firstName");
    this.validationContext?.validateField("customer.lastName");
    this.validationContext?.validateField("customer.email");
    this.validationContext?.validateField("customer.phone");
    this.validationContext?.validateField("customer.birthDate");
    this.validationContext?.validateField("customer.birthCity");
    this.validationContext?.validateField("customer.birthCountry");
  },
  methods: {
    blockEnterBehavior(event) {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
      }
    },
    updateAddress(value) {
      this.$emit("newAddress", value);
    },
  },
};
</script>
