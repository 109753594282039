<template>
  <div>
    <Combobox as="div" v-model="selectedSeller" by="id">
      <ComboboxLabel class="block text-sm text-gray-700 flex justify-between">
        <div>
          {{ $t("Select a seller") }}
          <span class="text-red-600">*</span>
        </div>
        <div>
          <a @click="isSellerCreationOpen = true" class="text-primary-dark underline cursor-pointer">
            {{ $t("Add a new seller") }}
          </a>
        </div>
      </ComboboxLabel>
      <div class="relative mt-1" ref="comboboxWrapper">
        <ComboboxInput
          class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm"
          @input="updateQuery"
          @keydown="blockEnterBehavior"
          :display-value="comboboxInputValue"
          autocomplete="off"
          @click="openCombobox"
          ref="comboboxInput"
        />
        <ComboboxButton
          ref="comboboxButton"
          class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
        >
          <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          <button
            v-if="selectedSeller?.name !== '' || query"
            type="button"
            @keydown="blockEnterBehavior"
            @click="resetAttributes"
          >
            <XIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
          </button>
        </ComboboxButton>

        <ComboboxOptions
          v-if="query || sellers.length > 0"
          class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <ComboboxOption v-for="seller in sellers" :key="seller.id" :value="seller" v-slot="{ active, selected }">
            <li
              :class="[
                'relative cursor-default select-none py-2 pl-8 pr-4',
                active ? 'text-button-font-primary bg-primary' : 'text-gray-900',
              ]"
            >
              <span :class="['block truncate', selected && 'font-semibold', 'text-xs sm:text-base']">
                {{ seller.name }}
              </span>

              <span
                v-if="selected"
                :class="[
                  'absolute inset-y-0 left-0 flex items-center pl-1.5',
                  active ? 'text-white' : 'text-primary-dark',
                ]"
              >
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ComboboxOption>
        </ComboboxOptions>
        <ComboboxOptions
          v-if="sellers.length == 0"
          class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <ComboboxOption>
            <li class="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900">
              <span>{{ $t("Add this seller by clicking on 'Add a new seller'") }}</span>
            </li>
          </ComboboxOption>
        </ComboboxOptions>
      </div>
    </Combobox>
    <SellerCreation
      v-bind:isOpen="isSellerCreationOpen"
      @closeSellerCreation="closeSellerCreation"
      @getSellers="retrieveSellers"
    />
  </div>
</template>

<script>
import { CheckIcon, SelectorIcon, XIcon } from "@heroicons/vue/solid";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/vue";
import StoreService from "@/api/services/store";
import { mapState } from "vuex";
import { isPresent } from "@/utils/validation";

import SellerCreation from "@/views/pos/seller/SellerCreation.vue";

export default {
  props: ["sellerCompleteName"],

  components: {
    CheckIcon,
    SelectorIcon,
    XIcon,
    Combobox,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
    ComboboxButton,
    SellerCreation,
  },

  data() {
    return {
      query: "",
      selectedSeller: null,
      sellers: [],
      debounce: null,
      isSellerCreationOpen: false,
    };
  },

  watch: {
    selectedSeller(newSelectedSeller) {
      this.query = newSelectedSeller ? newSelectedSeller.name : "";
      this.$emit("selectedSeller", newSelectedSeller);
    },
    query() {
      this.debounceSearch();
    },
    sellerCompleteName(newSellerCompleteName) {
      if (!isPresent(newSellerCompleteName)) {
        this.resetAttributes();
      }
    },
    selectedStoreId(newValue) {
      if (newValue !== 0) {
        this.retrieveSellers();
      }
    },
  },

  computed: {
    ...mapState("store", ["selectedStoreId"]),
  },

  beforeMount() {
    this.retrieveSellers();
  },

  methods: {
    closeSellerCreation() {
      this.isSellerCreationOpen = false;
    },

    comboboxInputValue(seller) {
      return isPresent(seller) ? seller.name : this.query;
    },

    openCombobox() {
      this.$refs.comboboxButton.$el.click();
    },

    resetAttributes() {
      this.selectedSeller = null;
      this.query = "";
    },

    debounceSearch() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.retrieveSellers();
      }, 300);
    },

    async retrieveSellers() {
      const storeId = this.selectedStoreId;

      const response = await StoreService.getSellers(storeId, this.query);
      if (response?.success) this.sellers = response.data.sellers;
    },

    updateQuery(event) {
      this.query = event.target.value;
    },

    blockEnterBehavior(event) {
      if (event.key === "Enter") {
        // Block enter behaviour
        event.preventDefault();
      }
    },
  },
};
</script>
