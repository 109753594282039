<template>
  <div v-if="invoice">
    <div class="flex justify-between items-center my-4">
      <h1 class="text-4xl font-heading-1 pl-2 tracking-tight text-gray-900">
        {{ invoiceTitle }} - {{ invoice.period }}
      </h1>
      <p>{{ invoice.entityName }}</p>
    </div>
    <div class="bg-white rounded-xl py-6 px-4 mt-4 mb-8 flex justify-between items-center">
      <h3>{{ invoiceAmountSummary }} : {{ formatPrice(invoice.amount) }}</h3>
      <div class="flex cursor-pointer" @click.stop="downloadInvoice(invoice)">
        <CloudDownloadIcon class="h-6 w-6 text-primary-dark" aria-hidden="true" />
        <a class="pl-2 text-base font-semibold text-primary-dark">
          {{ $t("Download") }}
        </a>
      </div>
    </div>
    <div>
      <div class="mb-4">
        <div v-if="tabs.length > 1">
          <div class="border-b border-gray-200">
            <nav class="-mb-px flex space-x-8" aria-label="Tabs">
              <a
                v-for="tab in tabs"
                :key="tab.id"
                :href="generateTabLink(tab.id)"
                @click.prevent="selectTab(tab.id)"
                :class="[
                  tab.active
                    ? 'border-primary text-primary-dark'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer',
                ]"
              >
                {{ tab.name }}
              </a>
            </nav>
          </div>
        </div>
      </div>

      <component :is="currentTab.component" :invoice="invoice" />
    </div>
  </div>
</template>

<script>
import AdminService from "@/api/services/admin";
import { mapActions, mapState } from "vuex";
import { formatPrice } from "@estaly/ui/src/utils/price_formatter";
import { isPresent } from "@/utils/validation";
import InvoiceBreakdown from "@/components/invoice/InvoiceBreakdown.vue";
import SellerRetributions from "@/components/invoice/SellerRetributions.vue";
import InvoiceItems from "@/components/invoice/InvoiceItems.vue";
import { CloudDownloadIcon } from "@heroicons/vue/outline";

export default {
  components: {
    CloudDownloadIcon,
    InvoiceBreakdown,
    SellerRetributions,
    InvoiceItems,
  },
  data() {
    return {
      invoice: null,
      selectedTabId: "invoice-breakdown",
    };
  },

  computed: {
    ...mapState("store", ["stores"]),

    currentTab() {
      return this.tabs.find((tab) => tab.id === this.selectedTabId);
    },

    invoiceTitle() {
      if (!this.invoice) return "";
      if (this.invoice.transactionType === "statement") {
        return this.$t("invoice.statementTitle");
      } else if (this.invoice.transactionType === "bill") {
        return this.$t("invoice.billTitle");
      }
      return "";
    },

    invoiceAmountSummary() {
      if (!this.invoice) return "";
      if (this.invoice.transactionType === "statement") {
        return this.$t("invoice.statementAmount");
      } else if (this.invoice.transactionType === "bill") {
        return this.$t("invoice.billAmount");
      }
      return "";
    },

    tabs() {
      const items = [
        {
          id: "invoice-breakdown",
          name: "Répartition des ventes",
          active: true,
          component: InvoiceBreakdown,
        },
      ];
      if (this.showSellerRetributionsTab) {
        items.push({
          id: "seller-retributions",
          name: "Primes vendeurs",
          active: false,
          component: SellerRetributions,
        });
      }
      items.push({
        id: "invoice-items",
        name: "Détails des encaissements",
        active: false,
        component: InvoiceItems,
      });
      return items;
    },

    showSellerRetributionsTab() {
      if (this.invoice?.subjectType === "Store") {
        const subject = this.stores.find((store) => store.id === this.invoice.subjectId);
        return subject?.source === "offline";
      } else if (this.invoice?.subjectType === "Organization") {
        return this.stores.some((store) => store.source === "offline");
      }
      console.error("Unrecognized invoice subject type", this.invoice?.subjectType);
      return true;
    },
  },

  beforeMount() {
    this.syncTabWithUrl();
    this.getInvoice();
  },

  watch: {
    $route: "syncTabWithUrl",
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    formatPrice,
    isPresent,

    selectTab(selectedTabId, replaceUrl = true) {
      this.selectedTabId = selectedTabId;
      this.tabs.forEach((tab) => {
        tab.active = tab.id === selectedTabId;
      });

      if (replaceUrl) {
        this.$router.push({ query: { tab: selectedTabId } });
      } else {
        this.$router.replace({ query: { tab: selectedTabId } });
      }
    },

    syncTabWithUrl() {
      const tabId = this.$route.query.tab || "invoice-breakdown";
      if (tabId !== this.currentTab.id) {
        this.selectTab(tabId, false);
      }
    },

    generateTabLink(tabId) {
      return this.$router.resolve({ query: { tab: tabId } }).href;
    },

    async getInvoice() {
      const response = await AdminService.getInvoiceByCustomId(this.$route.params.id);
      if (response?.success) {
        this.invoice = response.data.invoice;
      }
    },

    downloadInvoice(invoice) {
      window.open(invoice.fileUrl, "_blank");
    },
  },
};
</script>
