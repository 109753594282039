<template>
  <Simulation
    v-if="currentScreenSize < screens.lg"
    :price="product.price"
    :productCategoryValue="product.category"
    :offeredMonths="offeredMonths"
    class="block mb-4"
  />
  <VeeForm v-slot="{ handleSubmit }" :validation-schema="leadFormSchema" as="div" ref="leadCreationForm">
    <form @submit="handleSubmit($event, createLead)">
      <div class="sm:inline lg:flex">
        <div class="lg:w-4/5 lg:mr-4">
          <SellerForm :sellerCompleteName="sellerCompleteName" @selectedSeller="handleSelectSeller" />
          <CategorySelector @selectProductCategory="setProductCategory" :categories="categories" />
          <ProductForm v-model="product" :bikesContracts="bikesContracts" />
          <CustomerFormShort v-model="customer" />
        </div>
        <div class="lg:w-2/5 mt-4 lg:mt-0" v-if="currentScreenSize >= screens.lg">
          <Simulation :price="product.price" :productCategoryValue="product.category" :offeredMonths="offeredMonths" />
          <ContractDetailsSection :categories="categories" :productCategoryValue="product.category" />
          <LeadFormButtons @sendTextMessage="sendTextMessage" @paymentInScreen="handlePaymentInScreen" />
        </div>
      </div>
    </form>
  </VeeForm>
  <CustomerFormShortDetailsModal
    :open="openCustomerFormShortDetailsModal"
    :estalyId="lead.estalyId"
    @close="closeCustomerFormShortDetailsModal"
    @showOfferPayment="showOfferPayment"
  />
  <OfferPayment
    :isOpen="openOfferPayment"
    :lead="lead"
    :product="product"
    :customer="customer"
    @closePaymentModal="closePaymentModal"
    @updateOffer="handleUpdateOffer"
  />
</template>

<script>
import { Form as VeeForm } from "vee-validate";
import { leadFormSchema } from "@/components/pos/subscription/utils/validation";
import { mapActions, mapState } from "vuex";

import SellerForm from "@/components/pos/subscription/form/SellerForm.vue";
import ProductForm from "@/components/pos/subscription/form/ProductForm.vue";
import CustomerFormShort from "@/components/pos/subscription/form/CustomerFormShort.vue";
import CategorySelector from "@/components/selector/CategorySelector.vue";
import LeadFormButtons from "@/components/pos/subscription/form/LeadFormButtons.vue";
import Simulation from "@/components/pos/Simulation.vue";
import ContractDetailsSection from "@/components/pos/ContractDetailsSection.vue";
import CustomerFormShortDetailsModal from "@/components/pos/subscription/form/CustomerFormShortDetailsModal.vue";
import OfferPayment from "@/components/pos/subscription/payment/OfferPayment.vue";

import LeadService from "@/api/services/lead";
import { EventBus } from "@/utils/event_bus";
import { screenSize, screens } from "@/utils/breakpoints.js";
import { toCamelCase } from "@estaly/ui/src/utils/data_formatter";
import { isPresent } from "@/utils/validation";
import _ from "lodash";

export default {
  components: {
    VeeForm,
    SellerForm,
    CategorySelector,
    ProductForm,
    CustomerFormShort,
    LeadFormButtons,
    ContractDetailsSection,
    Simulation,
    CustomerFormShortDetailsModal,
    OfferPayment,
  },
  props: ["categories", "marketplace", "offeredMonths"],

  data() {
    return {
      screens,
      leadFormSchema,
      lead: {
        id: "",
        estalyId: "",
        subscriptionLink: "",
        offers: [],
        discount: "",
        product: {
          title: "",
          price: "",
          referenceId: "",
          category: this.categories[0]?.value,
        },
        customer: {
          email: "",
          phone: "",
          firstName: "",
          lastName: "",
        },
        invitationSentByEstaly: true,
        paymentInStorePlatform: false,
        sellerId: "",
      },
      sellerCompleteName: "",
      openCustomerFormShortDetailsModal: false,
      openOfferPayment: false,
    };
  },

  computed: {
    ...mapState("store", ["selectedStoreId"]),

    product: {
      get() {
        return this.lead.product;
      },
      set(value) {
        this.lead.product = value;
      },
    },

    customer: {
      get() {
        return this.lead.customer;
      },
      set(value) {
        this.lead.customer = value;
      },
    },

    bikesContracts() {
      return this.product.category === "bike";
    },

    currentScreenSize() {
      return screenSize();
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    ...mapActions("store", ["withLoader"]),

    handleSelectSeller(seller) {
      this.sellerCompleteName = seller ? seller.name : "";
      this.lead.sellerId = seller ? seller.id : "";
    },

    handleSelectProduct(product) {
      this.product.title = isPresent(product) && product.title.length > 0 ? product.title : "";
    },

    setProductCategory(selectedProductCategory) {
      this.product = {
        title: "",
        price: "",
        referenceId: "",
        category: selectedProductCategory?.value,
      };
    },

    resetAttributes() {
      this.lead = {
        id: "",
        estalyId: "",
        subscriptionLink: "",
        offers: [],
        discount: "",
        product: {
          title: "",
          price: "",
          referenceId: "",
          category: this.categories[0]?.value,
        },
        customer: {
          email: "",
          phone: "",
          firstName: "",
          lastName: "",
        },
        sellerId: "",
        invitationSentByEstaly: true,
        paymentInStorePlatform: false,
      };

      this.openCustomerFormShortDetailsModal = false;
      this.openOfferPayment = false;

      this.sellerCompleteName = "";

      EventBus.emit("resetProductCategory");
      this.$refs.leadCreationForm.resetForm();
    },

    handlePaymentInScreen() {
      this.lead.invitationSentByEstaly = false;
      this.lead.paymentInStorePlatform = true;
    },

    sendTextMessage() {
      this.lead.invitationSentByEstaly = true;
    },

    showOfferPayment() {
      this.openCustomerFormShortDetailsModal = false;
      this.openOfferPayment = true;
    },

    closePaymentModal(resetAttributes) {
      if (resetAttributes) {
        this.resetAttributes();
      } else {
        this.openOfferPayment = false;
        this.openCustomerFormShortDetailsModal = false;
      }
    },

    closeCustomerFormShortDetailsModal() {
      this.openCustomerFormShortDetailsModal = false;
    },

    handleUpdateOffer({ index, offer }) {
      this.lead.offers = [...this.lead.offers.slice(0, index), offer, ...this.lead.offers.slice(index + 1)];
    },

    async createLead() {
      const params = {
        lead: _.pick(this.lead, [
          "customer",
          "product",
          "invitationSentByEstaly",
          "paymentInStorePlatform",
          "sellerId",
        ]),
      };

      await this.withLoader(async () => {
        const response = await LeadService.create(this.selectedStoreId, params);
        if (response?.success) {
          const data = toCamelCase(response.data);
          if (this.lead.paymentInStorePlatform) {
            Object.assign(this.lead, {
              id: data.lead.id,
              estalyId: data.lead.estalyId,
              offers: data.lead.offers,
              discount: data.lead.discount,
            });
            this.openCustomerFormShortDetailsModal = true;
            this.lead.paymentInStorePlatform = false;
          } else {
            this.notify({
              category: "simple",
              type: "success",
              title: "Le devis a été envoyé avec succès.",
            });
            this.resetAttributes();
          }
        }
      });
    },
  },
};
</script>
