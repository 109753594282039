<template>
  <div class="bg-white p-4 rounded-md">
    <p class="text-xl font-heading-1 pb-2 tracking-tight text-gray-900">
      {{ $t("Product informations") }}
    </p>

    <div>
      <OfflineProductsCombobox
        :title="localProduct.title"
        :category="localProduct.category"
        @selectProduct="handleSelectProduct"
      />
      <div class="mt-1">
        <Field
          id="title"
          name="product.title"
          type="text"
          class="shadow-sm focus:ring-primary focus:border-primary w-full border-gray-300 rounded-md hidden"
          placeholder="RIESE & MÜLLER - MULTICHARGER MIXTE 51 - GT Rohloff"
          v-model="localProduct.title"
        />
        <ErrorMessage name="product.title" class="mt-2 text-sm text-red-600" />
      </div>
    </div>

    <div class="grid grid-cols-2 gap-x-4 gap-y-3">
      <div class="flex-1 mt-1">
        <label for="price" class="flex text-sm text-gray-700">
          <div v-if="bikesContracts">
            {{ $t("Product price + accessories incl. VAT") }}
          </div>
          <div v-else>{{ $t("Product price") }}</div>
          <span class="ml-1 text-red-600">*</span>
        </label>
        <div class="mt-1 relative rounded-md shadow-sm">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span class="text-gray-500 sm:text-sm">€</span>
          </div>
          <Field
            id="price"
            name="product.price"
            type="text"
            class="pl-7 pr-12 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="3000,0"
            aria-describedby="price-currency"
            v-model="priceFormatted"
            @keydown="blockEnterBehavior"
          />
          <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span class="text-gray-500 sm:text-sm" id="price-currency">EUR</span>
          </div>
        </div>
        <ErrorMessage name="product.price" class="mt-2 text-sm text-red-600" />
      </div>

      <div class="flex-1 mt-1">
        <label for="referenceId" class="flex items-center text-sm text-gray-700">
          <div>{{ $t("Product reference") }}</div>
          <span class="ml-1 text-red-600">*</span>
        </label>
        <div class="mt-1">
          <Field
            id="referenceId"
            name="product.referenceId"
            type="text"
            class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="861536030196001"
            v-model="localProduct.referenceId"
            @keydown="blockEnterBehavior"
          />
          <ErrorMessage name="product.referenceId" class="mt-2 text-sm text-red-600" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";
import OfflineProductsCombobox from "@/components/combobox/OfflineProductsCombobox.vue";

export default {
  components: {
    Field,
    ErrorMessage,
    OfflineProductsCombobox,
  },
  props: {
    modelValue: Object,
    bikesContracts: Boolean,
  },
  inject: ["validationContext"],
  computed: {
    localProduct: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    priceFormatted: {
      get() {
        return this.localProduct.price == null ? "" : this.localProduct.price.toString().replace(".", ",");
      },
      set(value) {
        this.localProduct = { ...this.localProduct, price: value.replace(",", ".") };
      },
    },
  },
  mounted() {
    this.validationContext?.validateField("product.title");
    this.validationContext?.validateField("product.price");
    this.validationContext?.validateField("product.referenceId");
  },
  methods: {
    handleSelectProduct(product) {
      this.localProduct = {
        ...this.localProduct,
        title: product?.title || "",
        price: "",
        referenceId: "",
      };

      this.$nextTick(() => {
        this.validationContext?.setFieldValue("product.title", this.localProduct.title);
        this.validationContext?.setFieldValue("product.price", this.priceFormatted);
        this.validationContext?.setFieldValue("product.referenceId", this.localProduct.referenceId);

        this.validationContext?.setTouched("product.title", true);
        this.validationContext?.setTouched("product.price", true);
        this.validationContext?.setTouched("product.referenceId", true);

        this.validationContext?.validateField("product.title");
        this.validationContext?.validateField("product.price");
        this.validationContext?.validateField("product.referenceId");
      });
    },
    blockEnterBehavior(event) {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
      }
    },
  },
};
</script>
