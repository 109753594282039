<template>
  <LeadForm
    v-if="isLeadSubscriptionForm"
    :marketplace="marketplace"
    :categories="categories"
    :offeredMonths="offeredMonths"
  />
  <SaleForm v-else :marketplace="marketplace" :categories="categories" :offeredMonths="offeredMonths" />
</template>

<script>
import StoreService from "@/api/services/store";
import { mapState, mapGetters } from "vuex";
import SaleForm from "@/components/pos/subscription/SaleForm.vue";
import LeadForm from "@/components/pos/subscription/LeadForm.vue";

export default {
  components: {
    SaleForm,
    LeadForm,
  },

  data: () => {
    return {
      marketplace: false,
      categories: [],
      offeredMonths: null,
    };
  },

  watch: {
    selectedStoreId(newValue) {
      if (newValue !== 0) {
        this.getCategories();
      }
    },
  },

  computed: {
    ...mapState("store", ["selectedStoreId"]),
    ...mapGetters("store", ["selectedStore"]),

    isLeadSubscriptionForm() {
      return this.selectedStore?.storeFeatureConfiguration?.subscriptionForm === "lead";
    },
  },

  beforeMount() {
    this.getCategories();
    this.getDiscounts();
  },

  methods: {
    async getCategories() {
      const response = await StoreService.getCategories(this.selectedStoreId);
      if (response?.success) {
        this.marketplace = response.data.marketplace;
        this.categories = response.data.categories;
      }
    },

    async getDiscounts() {
      const response = await StoreService.getDiscount(this.selectedStoreId);
      if (!response?.success) return;

      this.offeredMonths = response.data.discount.offeredMonths;
    },
  },
};
</script>
