<template>
  <div>
    <h1 class="text-center font-extrabold text-xl">Modifier le profil de {{ user.name }}</h1>
    <div class="grid grid-cols-2 gap-4">
      <Selector id="role" :label="$t('Role')" name="role" v-model="role" :options="roles" />
    </div>

    <div class="mt-4 flex justify-end">
      <Loading v-if="isLoading" />
      <GenericButton v-else @click="submit">
        {{ $t("Validate") }}
      </GenericButton>
    </div>

    <div v-if="error" class="my-3">
      <span class="text-red-500 text-sm">{{ error }}</span>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

import UserService from "@/api/services/user";
import GenericButton from "@/components/utils/GenericButton.vue";
import { Loading } from "@estaly/ui";
import { Selector } from "@estaly/ui";

export default {
  components: {
    GenericButton,
    Loading,
    Selector,
  },

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      role: this.user.role.replace("organizationmanager", "organization_manager"),
      roles: [],
      error: "",
    };
  },

  created() {
    this.setupFormData();
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    async setupFormData() {
      this.isLoading = true;
      const response = await UserService.newUser();
      if (!response?.success) return;

      this.roles = response.data.roles;
      this.isLoading = false;
    },

    async submit() {
      this.isLoading = true;
      const formData = {
        role: this.role,
        type: this.user.type,
      };
      const response = await UserService.updateProfileRole(this.user.id, formData);
      this.isLoading = false;
      if (!response?.success) return;

      this.notify({
        category: "simple",
        type: "success",
        title: "Mise à jour effectuée",
      });
      this.$emit("success");
    },
  },
};
</script>
