<template>
  <div>
    <h1 class="text-4xl font-heading-1 pl-2 tracking-tight text-gray-900 my-4">
      {{ $t("Leads") }}
    </h1>
    <div class="mb-6 flex flex-col sm:w-1/4 sm:flex-row w-full">
      <DateRangePicker placeholder="Sélectionner une période" v-model="dateRange" use-query-params />
    </div>
    <SearchBar
      class="mb-6"
      :placeholder="$t('Rechercher un devis par nom, prénom, email ou numéro de téléphone')"
      :current-page="currentPage"
      :available-filters="availableFiltersWithOptions"
      :selected-filters="currentFilteringData"
      :initial-keywords="currentFilteringData.keywords"
      @updateFilterSearch="updateCurrentFilteringData"
      @updateKeywordSearch="updateKeywordSearch"
    />
    <div v-if="leads.length === 0" class="text-center mt-4 py-2">
      <p>Aucun devis</p>
    </div>
    <Table v-else :columns="columns" :data="formattedData">
      <template #subscriptionLink="{ row }">
        <div class="flex gap-x-2">
          <a :href="row.subscriptionLink" class="text-primary underline" target="_blank">{{ row.subscriptionLink }}</a>
          <ClipboardIcon class="cursor-pointer w-4 h-4 text-gray-300" @click="copyToClipboard(row.subscriptionLink)" />
        </div>
      </template>
    </Table>
    <Pagination
      :currentPage="currentPage"
      :totalPages="totalPages"
      :totalCount="totalCount"
      @changePage="handleChangePage"
    />
  </div>
</template>

<script>
import { Pagination, Table, DateRangePicker } from "@estaly/ui";
import { ClipboardIcon } from "@heroicons/vue/solid";
import SearchBar from "@/components/menu/SearchBar.vue";

import { mapGetters } from "vuex";
import LeadService from "@/api/services/lead";
import { toCamelCase } from "@estaly/ui/src/utils/data_formatter";

export default {
  components: { Pagination, Table, DateRangePicker, ClipboardIcon, SearchBar },

  data() {
    return {
      leads: [],
      currentPage: 1,
      totalPages: null,
      totalCount: null,
      currentFilteringData: {
        keywords: null,
        startDate: null,
        endDate: null,
        status: [],
      },
      filters: {
        status: [],
      },
      columns: [
        { label: "Date de création", field: "creationDate" },
        { label: "Email", field: "customerEmail" },
        { label: "Formule", field: "categoryLabel" },
        { label: "Date d'expiration", field: "expirationDate" },
        {
          label: "Statut",
          field: "status",
          class: {
            live: "p-1 rounded-lg bg-yellow-50 text-yellow-600",
            expired: "p-1 rounded-lg bg-gray-50 text-gray-600",
            converted: "p-1 rounded-lg bg-green-50 text-green-600",
            waiting_for_confirmation: "p-1 rounded-lg bg-blue-50 text-blue-600",
          },
        },
        { label: "Lien", field: "subscriptionLink", slot: "subscriptionLink" },
      ],
    };
  },

  computed: {
    ...mapGetters("store", ["selectedStoreId"]),

    dateRange: {
      get() {
        return [this.currentFilteringData.startDate, this.currentFilteringData.endDate];
      },
      set(value) {
        if (value && value.length === 2) {
          this.updateDateFilters(value[0], value[1]);
        } else {
          this.updateDateFilters(null, null);
        }
      },
    },

    availableFiltersWithOptions() {
      return Object.fromEntries(
        Object.entries(this.filters).filter(([filterKey, filterOptions]) => filterKey && filterOptions?.length > 1),
      );
    },

    formattedData() {
      return this.leads.map((lead) => ({
        creationDate: lead.purchaseDate,
        customerEmail: lead.customer.email,
        categoryLabel: lead.categoryLabel,
        expirationDate: lead.expirationDate,
        status: lead.status,
        subscriptionLink: lead.subscriptionLink,
      }));
    },
  },

  created() {
    this.getLeads();
  },

  methods: {
    copyToClipboard(subscriptionLink) {
      navigator.clipboard.writeText(subscriptionLink);
    },

    handleChangePage(page) {
      this.currentPage = page;
      this.getLeads();
    },

    updateCurrentFilteringData(filterName, filterOptions) {
      this.currentFilteringData[filterName] = filterOptions;
      this.handleChangePage(1);
    },

    updateKeywordSearch(keywords) {
      this.currentFilteringData["keywords"] = keywords;
      this.currentPage = 1;
      this.getLeads();
    },

    updateDateFilters(startDate, endDate) {
      this.currentFilteringData["startDate"] = startDate;
      this.currentFilteringData["endDate"] = endDate;
      this.handleChangePage(1);
    },

    async getLeads() {
      const params = {
        storeIds: [this.selectedStoreId],
        page: this.currentPage,
        paymentInStorePlatform: false,
        type: ["lead"],
        keywords: this.currentFilteringData.keywords,
        status: this.currentFilteringData.status,
        startDate: this.currentFilteringData.startDate,
        endDate: this.currentFilteringData.endDate,
      };

      const response = await LeadService.list(params);

      if (!response?.success) return;

      const data = toCamelCase(response.data);
      this.leads = data.leads;
      this.filters = data.filters;
      this.totalCount = data.page.totalCount;
      this.totalPages = data.page.totalPages;
    },
  },
};
</script>
