<template>
  <div>
    <Header />
    <SellerRetributionsRanking status="paid" />
  </div>
</template>

<script>
import Header from "@/components/pos/retributions/Header.vue";
import SellerRetributionsRanking from "@/components/pos/retributions/SellerRetributionsRanking.vue";

export default {
  components: {
    Header,
    SellerRetributionsRanking,
  },
};
</script>
