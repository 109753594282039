<template>
  <Simulation
    v-if="currentScreenSize < screens.lg"
    :price="product.price"
    :productCategoryValue="product.productCategory"
    :offeredMonths="offeredMonths"
    class="block mb-4"
  />
  <VeeForm v-slot="{ handleSubmit }" :validation-schema="saleFormSchema" as="div" ref="leadCreationForm">
    <form @submit="handleSubmit($event, submitLeadCreation)">
      <div class="sm:inline lg:flex">
        <div class="lg:w-4/5 lg:mr-4">
          <SellerForm :sellerCompleteName="sellerCompleteName" @selectedSeller="handleSelectSeller" />
          <CategorySelector @selectProductCategory="setProductCategory" :categories="categories" />
          <ProductForm v-model="product" :bikesContracts="bikesContracts" />
          <CustomerFormLong
            v-model="customer"
            :address2="address.address2"
            :addressError="address.addressError"
            @update:address2="address.address2 = $event"
            @newAddress="newAddress"
          />
        </div>
        <div class="lg:w-2/5 mt-4 lg:mt-0" v-if="currentScreenSize >= screens.lg">
          <Simulation :price="product.price" :productCategoryValue="product.category" :offeredMonths="offeredMonths" />
          <ContractDetailsSection :categories="categories" :productCategoryValue="product.category" />
          <SaleFormButtons
            @sendTextMessage="sendTextMessage"
            @paymentInScreen="handlePaymentInScreen"
            @recordASale="handleSaleRecord"
          />
        </div>
      </div>
    </form>
  </VeeForm>
  <SaleCreation
    :isOpen="leadConfiguration.isSaleCreationOpen"
    :saleRecording="saleRecording"
    :customer="customer"
    :product="product"
    :address="address"
    :sellerId="lead.sellerId"
    @closeSaleModal="closeSaleModal"
  />
  <OfferPayment
    :isOpen="leadConfiguration.isPaymentOpen"
    :lead="lead"
    :product="product"
    :customer="customer"
    @closePaymentModal="closePaymentModal"
    @updateOffer="handleUpdateOffer"
  />
</template>

<script>
import { Form as VeeForm } from "vee-validate";
import { isPresent } from "@/utils/validation";
import { saleFormSchema } from "@/components/pos/subscription/utils/validation";
import { mapActions, mapState } from "vuex";

import SellerForm from "@/components/pos/subscription/form/SellerForm.vue";
import ProductForm from "@/components/pos/subscription/form/ProductForm.vue";
import CategorySelector from "@/components/selector/CategorySelector.vue";
import CustomerFormLong from "@/components/pos/subscription/form/CustomerFormLong.vue";
import SaleFormButtons from "@/components/pos/subscription/form/SaleFormButtons.vue";
import Simulation from "@/components/pos/Simulation.vue";
import SaleCreation from "@/components/pos/subscription/salecreation/SaleCreation.vue";
import OfferPayment from "@/components/pos/subscription/payment/OfferPayment.vue";
import SaleService from "@/api/services/sale";
import ContractDetailsSection from "@/components/pos/ContractDetailsSection.vue";

import LeadService from "@/api/services/lead";
import { EventBus } from "@/utils/event_bus";
import { screenSize, screens } from "@/utils/breakpoints.js";
import { toCamelCase } from "@estaly/ui/src/utils/data_formatter";
import _ from "lodash";

export default {
  components: {
    VeeForm,
    SellerForm,
    CategorySelector,
    ProductForm,
    CustomerFormLong,
    ContractDetailsSection,
    SaleFormButtons,
    Simulation,
    SaleCreation,
    OfferPayment,
  },
  props: ["categories", "marketplace", "offeredMonths"],

  data() {
    return {
      screens,
      saleFormSchema,
      lead: {
        id: "",
        uniqueToken: "",
        subscriptionLink: "",
        offers: [],
        discount: "",
        product: {
          title: "",
          price: "",
          referenceId: "",
          category: this.categories[0]?.value,
        },
        customer: {
          email: "",
          phone: "",
          firstName: "",
          lastName: "",
          birthDate: "",
          birthCity: "",
          birthCountry: "",
        },
        address: {
          address1: "",
          address2: "",
          city: "",
          province: "",
          country: "",
          zipCode: "",
          addressError: false,
        },
        invitationSentByEstaly: true,
        paymentInStorePlatform: false,
        sellerId: "",
      },
      leadConfiguration: {
        recordASale: false,
        isSaleCreationOpen: false,
        isPaymentOpen: false,
      },
      saleRecording: {
        contractDetails: [],
        monthlyPrice: "",
      },
      sellerCompleteName: "",
    };
  },

  computed: {
    ...mapState("store", ["selectedStoreId"]),

    product: {
      get() {
        return this.lead.product;
      },
      set(value) {
        this.lead.product = value;
      },
    },

    customer: {
      get() {
        return this.lead.customer;
      },
      set(value) {
        this.lead.customer = value;
      },
    },

    address: {
      get() {
        return this.lead.address;
      },
      set(value) {
        this.lead.address = value;
      },
    },

    bikesContracts() {
      return this.product.category === "bike";
    },

    currentScreenSize() {
      return screenSize();
    },
  },

  watch: {
    phone(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.cleanPhone();
      }
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    ...mapActions("store", ["withLoader"]),

    newAddress(value) {
      this.address = value;
      if (isPresent(this.address.address1) && isPresent(this.address.city) && isPresent(this.address.country)) {
        this.address.addressError = false;
      }
    },

    cleanPhone() {
      this.customer.phone = this.customer.phone.replace(/\s+/g, "");
    },

    setProductCategory(selectedProductCategory) {
      this.product = {
        title: "",
        price: "",
        referenceId: "",
        category: selectedProductCategory?.value,
      };
    },

    resetAttributes() {
      this.lead = {
        id: "",
        uniqueToken: "",
        subscriptionLink: "",
        offers: [],
        discount: "",
        product: {
          title: "",
          price: "",
          referenceId: "",
          category: this.categories[0]?.value,
        },
        customer: {
          email: "",
          phone: "",
          firstName: "",
          lastName: "",
          birthDate: "",
          birthCity: "",
          birthCountry: "",
        },
        address: {
          address1: "",
          city: "",
          province: "",
          country: "",
          zipCode: "",
          address2: "",
          addressError: false,
        },
        invitationSentByEstaly: true,
        paymentInStorePlatform: false,
        sellerId: "",
      };
      this.leadConfiguration = {
        recordASale: false,
        isSaleCreationOpen: false,
        isPaymentOpen: false,
      };
      this.saleRecording = {
        contractDetails: [],
        monthlyPrice: "",
      };
      this.sellerCompleteName = "";

      EventBus.emit("resetAddress");
      EventBus.emit("resetProductCategory");
      this.resetForm();
    },

    handleSaleRecord() {
      this.setInvitationSentByEstaly(true);
      this.leadConfiguration.recordASale = true;
    },

    sendTextMessage() {
      this.setInvitationSentByEstaly(true);
    },

    handlePaymentInScreen() {
      this.setInvitationSentByEstaly(false);
      this.lead.paymentInStorePlatform = true;
    },

    resetForm() {
      this.$refs.leadCreationForm.resetForm();
    },

    handleSelectProduct(product) {
      this.product.title = isPresent(product) && product.title.length > 0 ? product.title : "";
    },

    handleSelectSeller(seller) {
      this.sellerCompleteName = seller ? seller.name : "";
      this.lead.sellerId = seller ? seller.id : "";
    },

    setInvitationSentByEstaly(state) {
      this.lead.invitationSentByEstaly = state;
    },

    closeSaleModal(resetAttributes) {
      if (resetAttributes) {
        this.resetAttributes();
      } else {
        this.leadConfiguration.isSaleCreationOpen = false;
      }
    },

    closePaymentModal(resetAttributes) {
      if (resetAttributes) {
        this.resetAttributes();
      } else {
        this.leadConfiguration.isPaymentOpen = false;
      }
    },

    handleUpdateOffer({ index, offer }) {
      this.lead.offers = [...this.lead.offers.slice(0, index), offer, ...this.lead.offers.slice(index + 1)];
    },

    blockEnterBehavior(event) {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
      }
    },

    async submitLeadCreation() {
      if (isPresent(this.address.address1) && isPresent(this.address.city) && isPresent(this.address.country)) {
        if (this.leadConfiguration.recordASale) {
          await this.handleSaleRecording();
        } else {
          await this.handleLeadCreation();
        }
      } else {
        this.address.addressError = true;
      }
    },

    async handleSaleRecording() {
      await this.withLoader(async () => {
        const response = await SaleService.seeContractDetails(
          this.product.category,
          this.product.price,
          this.selectedStoreId,
        );
        if (response?.success) {
          const data = toCamelCase(response.data);
          this.saleRecording.contractDetails = data.contracts;
          this.monthlyPrice = (parseFloat(this.saleRecording.contractDetails[0].price) / 12).toFixed(2);
        }
      });
      this.leadConfiguration.isSaleCreationOpen = true;
      this.leadConfiguration.recordASale = false;
    },

    async handleLeadCreation() {
      const params = {
        lead: _.pick(this.lead, [
          "customer",
          "address",
          "product",
          "invitationSentByEstaly",
          "paymentInStorePlatform",
          "sellerId",
        ]),
      };

      await this.withLoader(async () => {
        const response = await LeadService.create(this.selectedStoreId, params);
        if (response?.success) {
          const data = toCamelCase(response.data);
          if (this.lead.paymentInStorePlatform) {
            Object.assign(this.lead, {
              id: data.lead.id,
              estalyId: data.lead.estalyId,
              offers: data.lead.offers,
              discount: data.lead.discount,
            });
            this.leadConfiguration.isPaymentOpen = true;
            this.lead.paymentInStorePlatform = false;
          } else {
            this.notify({
              category: "simple",
              type: "success",
              title: "The subscription link was successfully sent to the customer!",
            });
            this.resetAttributes();
          }
        }
      });
    },
  },
};
</script>
