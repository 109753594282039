import axios from "axios";
import axiosRetry from "axios-retry";
import {
  isNetworkError,
  isForbiddenError,
  isUnauthorized,
  isUnprocessableEntityError,
  isUnexpectedError,
  isNotFoundError,
  isMaintenanceError,
  isConflictError,
  isNotAcceptableError,
  isServerError,
} from "../../../../utils/errors";
import store from "../../store";
import { rollbar } from "../../rollbar";
import { i18n } from "@estaly/ui/src/utils/i18n";
import { debounce } from "../../../../utils/debounce";

export const ApiUrl = process.env.VUE_APP_API_BASE_URL;

export const plainAxiosInstance = axios.create({
  baseURL: ApiUrl,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const securedAxiosInstance = axios.create({
  baseURL: ApiUrl,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

axiosRetry(securedAxiosInstance, { retries: 1, retryCondition: isNetworkError });

plainAxiosInstance.interceptors.response.use(successResponse, async (error) => {
  await dispatchErrorNotification(error);
  return errorResponse(error.response);
});

securedAxiosInstance.interceptors.request.use((config) => {
  const method = config.method.toUpperCase();
  if (method !== "OPTIONS" && method !== "GET") {
    config.headers = {
      ...config.headers,
      "X-CSRF-TOKEN": store.state.auth.csrf,
    };
  }
  return config;
});

securedAxiosInstance.interceptors.response.use(successResponse, async (error) => {
  // In case 401 is caused by expired access cookie - we'll do refresh request
  if (isUnauthorized(error)) {
    refreshSessionAndRetryDebounced(); // Refresh page or redirect to signin
    return errorResponse({});
  }

  await dispatchErrorNotification(error);
  return errorResponse(error.response);
});

function successResponse(response) {
  return { success: true, ...response };
}

function errorResponse(response) {
  return { success: false, ...response };
}

const refreshSessionAndRetryDebounced = debounce(refreshSessionAndRetry, 300);

async function refreshSessionAndRetry() {
  const response = await plainAxiosInstance.post(
    "/user/refreshes",
    {},
    { headers: { "X-CSRF-TOKEN": store.state.auth.csrf } },
  );
  if (response?.success) {
    // After a successful token refresh, reload the whole page
    store.commit("auth/refresh", response.data.csrf);
    window.location.reload();
  } else {
    // redirect to signin in case refresh request fails
    sessionStorage.clear(); // Clear vuex states
    localStorage.clear();
    location.replace("/signin");
  }
}

async function dispatchErrorNotification(error) {
  const t = i18n.global.t;
  if (isUnauthorized(error)) await errorNotification(t("errors.Unauthorized"));
  else if (isForbiddenError(error)) await errorNotification(t("errors.Forbidden"));
  else if (isNotFoundError(error)) await errorNotification(t("errors.NotFound"));
  else if (isUnprocessableEntityError(error) || isNotAcceptableError(error))
    await errorNotification(t("errors.UnprocessableEntity"), error);
  else if (isConflictError(error)) await errorNotification(t("errors.Conflict"), error);
  else if (isMaintenanceError(error)) await errorNotification(t("errors.Maintenance"));
  else if (isNetworkError(error)) {
    await errorNotification(t("errors.Network"));
    rollbar.error(error);
  } else if (isUnexpectedError(error)) {
    await errorNotification(t("errors.Unexpected"), error);
    if (!isServerError(error)) {
      console.error(error);
      rollbar.error(error);
    }
  } else {
    throw error;
  }
}

export async function errorNotification(message, error) {
  const notification = {
    category: "simple",
    type: "error",
    title: message,
  };
  if (error) notification.text = error.response?.data?.error || error?.message;
  console.log("Error: ", message);
  await store.dispatch("notifications/notify", notification);
}
