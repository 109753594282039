<template>
  <div :class="editable ? 'retribution-table-editable' : ''">
    <Table :columns="columns" :data="rows" v-if="rows.length > 0" class="mt-2">
      <template #editable="{ row, column }">
        <div style="max-width: 6rem">
          <FormField
            v-if="retributionId(row)"
            :id="`retribution-${retributionId(row)}`"
            :name="column.field"
            v-model="row[column.field]"
            @input="$emit('editRetribution', retributionId(row), row[column.field])"
          />
        </div>
      </template>
      <template #retribution="{ row, column }">
        <div v-if="$slots.seller_retribution">
          <slot name="seller_retribution" :retributionId="retributionId(row)" />
        </div>
        <span v-else>{{ row[column.field] ? formatPrice(row[column.field]) : "—" }}</span>
      </template>
      <template #categoryName="{ row, colspan }">
        <td class="full-row bg-purple-200 px-4 py-2 text-xl font-heading-1" :colspan="colspan">
          {{ row.categoryName }}
        </td>
      </template>
      <template #duration="{ row, colspan }">
        <td class="full-row bg-purple-100 px-4 py-2 font-semibold" :colspan="colspan">{{ row.duration }}</td>
      </template>
    </Table>
    <Alert v-else>{{ $t("no_data") }}</Alert>
  </div>
</template>

<script>
import { formatPrice } from "@estaly/ui/src/utils/price_formatter";
import { formatPriceBand } from "@estaly/ui/src/utils/price_band_formatter";
import { Alert, Table } from "@estaly/ui";
import { FormField } from "@estaly/ui";

export default {
  emits: ["editRetribution"],

  components: { Alert, Table, FormField },

  props: {
    retributions: {
      type: Array,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    hideStoreRemuneration: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      defaultColumns: [
        { field: "priceBand", label: "Prix du produit" },
        { field: "price", label: "Prix de l’assurance", numeric: true },
        { field: "remuneration", label: "Rémunération", numeric: true },
        {
          field: "retribution",
          label: "Prime vendeur",
          numeric: true,
          slot: this.editable ? "editable" : "retribution",
        },
      ],
    };
  },

  computed: {
    columns() {
      return this.hideStoreRemuneration
        ? this.defaultColumns.filter((column) => column.field !== "remuneration")
        : this.defaultColumns;
    },

    rows() {
      return this.categories.map((category) => this.categoryRows(category)).flat();
    },

    categories() {
      return [...new Set(this.retributions.map((row) => row.category))];
    },

    months() {
      const months = this.retributions.filter((row) => !row.monthlyBilling);
      return [...new Set(months.map((row) => row.termLength))].sort((a, b) => a - b);
    },
  },

  methods: {
    formatPrice,

    retributionId(row) {
      return row["id"];
    },

    categoryRows(category) {
      const data = this.retributions.filter((retribution) => retribution.category === category);
      const rows = [{ fullRow: true, slot: "categoryName", categoryName: data[0].categoryName }];

      const monthlyRows = data
        .filter((retribution) => retribution.monthlyBilling)
        .map(this.row)
        .sort(this.sortRows);
      if (monthlyRows.length > 0) {
        rows.push({ fullRow: true, slot: "duration", duration: this.$t("termLength.monthly") });
        rows.push(...monthlyRows);
      }

      this.months.forEach((termLength) => {
        const yearlyRows = data
          .filter((retribution) => !retribution.monthlyBilling && retribution.termLength === termLength)
          .map(this.row)
          .sort(this.sortRows);
        if (yearlyRows.length > 0) {
          rows.push({ fullRow: true, slot: "duration", duration: this.$t(`termLength.${termLength}_months`) });
          rows.push(...yearlyRows);
        }
      });
      return rows;
    },

    row(retribution) {
      return {
        id: retribution.id,
        priceBandMin: retribution.priceBandMin,
        termLength: retribution.termLength,
        monthlyBilling: retribution.monthlyBilling,
        category: retribution.category,
        priceBand: formatPriceBand(retribution),
        price: formatPrice(retribution.price),
        remuneration: formatPrice(retribution.storeCommission),
        retribution: retribution.netAmount,
      };
    },

    formatDuration(retribution) {
      if (retribution.monthlyBilling) return this.$t("termLength.monthly");
      return this.$t(`termLength.${retribution.termLength}_months`);
    },

    sortRows(a, b) {
      if (a.termLength === b.termLength) return a.priceBandMin - b.priceBandMin;
      return a.termLength - b.termLength;
    },
  },
};
</script>
<style>
.retribution-table-editable td:not(.full-row) {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
</style>
