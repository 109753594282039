import StoreService from "../../api/services/store";

export default {
  namespaced: true,

  state: {
    selectedStoreId: null,
    retributions: [],
    loadingRetributions: false,
  },

  mutations: {
    setSelectedStoreId(state, storeId) {
      state.selectedStoreId = storeId;
    },

    setRetributions(state, retributions) {
      state.retributions = retributions;
    },

    setLoadingRetributions(state, loadingRetributions) {
      state.loadingRetributions = loadingRetributions;
    },
  },

  actions: {
    async fetchRetributions({ state, commit }) {
      commit("setLoadingRetributions", true);
      const response = await StoreService.getRetributions(state.selectedStoreId);
      commit("setLoadingRetributions", false);
      if (!response.success) return response;

      commit("setRetributions", response.data);
      return response;
    },

    async downloadRetributionsPdf(_context, storeId) {
      await StoreService.downloadRetributionsPdf(storeId);
    },
  },
};
