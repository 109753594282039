<template>
  <div>
    <div class="bg-white p-4 rounded-md">
      <p class="text-xl font-heading-1 pb-2 tracking-tight text-gray-900">
        {{ $t("Prices") }}
      </p>

      <p class="text-red-500" v-if="!productIsInsurable">
        {{ $t("There are no contracts available at this price.") }}
      </p>

      <div v-else-if="this.contracts.length > 0 || this.monthlyPrice">
        <div v-if="this.contracts.length > 0">
          <div v-for="(contract, idx) in sortedContractsByTermLength" :key="idx">
            <div class="flex justify-between">
              <div v-if="contract.isMonthly" class="font-medium text-sm">
                {{ $t("Monthly") }}
              </div>
              <div v-else class="font-medium text-sm">
                {{ contract.termLength / 12 }} an{{ contract.termLength > 12 ? "s" : "" }}
              </div>
              <div class="font-medium text-sm">
                {{ formatPrice(contract.price) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <p class="text-gray-500 text-sm" v-if="!productCategoryValue">
        {{ $t("Select an insurance plan.") }}
      </p>

      <p class="text-gray-500 text-sm" v-if="!isPresent(price) && productCategoryValue">
        {{ $t("Enter the price of the product to be insured.") }}
      </p>

      <div class="flex" v-if="availableOfferedMonths">
        <p class="text-primary-dark font-body-bold text-sm pt-2 flex">
          Vos clients bénéficient de {{ this.offeredMonths }} mois offerts par Estaly 🎁
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SaleService from "@/api/services/sale";
import { isPresent } from "@/utils/validation";
import { formatPrice } from "@estaly/ui/src/utils/price_formatter";
import { formatResponse } from "@/utils/backend_response_formatter";

export default {
  props: {
    price: String,
    productCategoryValue: String,
    offeredMonths: Number,
  },

  data: () => {
    return {
      contracts: [],
      monthlyPrice: null,
      productIsInsurable: true,
    };
  },

  computed: {
    ...mapState("store", ["selectedStoreId"]),
    sortedContractsByTermLength() {
      const contracts = this.contracts;
      const monthlyContracts = contracts.filter((contract) => contract.isMonthly);
      const annualContracts = contracts.filter((contract) => !contract.isMonthly);

      monthlyContracts.sort((a, b) => a.termLength - b.termLength);
      annualContracts.sort((a, b) => a.termLength - b.termLength);

      return [...annualContracts, ...monthlyContracts];
    },
    availableOfferedMonths() {
      return isPresent(this.offeredMonths);
    },
  },

  watch: {
    price() {
      if (isPresent(this.productCategoryValue) && isPresent(this.price)) {
        this.getContracts();
      } else {
        this.resetAttributes();
      }
    },
    productCategoryValue() {
      if (isPresent(this.productCategoryValue) && isPresent(this.price)) {
        this.getContracts();
      } else {
        this.resetAttributes();
      }
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    isPresent,
    formatPrice,

    resetAttributes() {
      this.contracts = [];
      this.monthlyPrice = null;
      this.productIsInsurable = true;
    },

    async getContracts() {
      const response = await SaleService.seeContractDetails(
        isPresent(this.productCategoryValue) ? this.productCategoryValue : "",
        this.price,
        this.selectedStoreId,
      );
      if (response?.success) {
        const formattedResponse = formatResponse(response.data);
        this.contracts = formattedResponse.contracts;
        this.monthlyPrice = formattedResponse.monthlyPriceEquivalent;
        this.productIsInsurable = formattedResponse.productIsInsurable;
      }
    },
  },
};
</script>
